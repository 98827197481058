import React from 'react';
import './NotFound.scss';
import notFound from '../../imgs/404.svg';
import {Link} from "@material-ui/core";
import AdminHeader from "../Admin/AdminHeader/AdminHeader";

const NotFound = () => {

    return (
        <div>
            <AdminHeader />
            <main className="not-found">
                <div className="not-found-container">
                    <div className="not-found-container-inner">
                        <div className="not-found-container-inner-left">
                            <h2>Oh la la !</h2>
                            <p>Nous ne parvenons pas a trouver la page que vous recherchez :)</p>
                            <Link href={'/dashboard/members'}>Retour</Link>
                        </div>
                        <div className="not-found-container-inner-right">
                            <img src={notFound} />
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default NotFound;
