import axios from 'axios';
import {
    apiUrl,
} from '../utils/axios';

export function getAllModels(brandId) {
    return axios.get(`${apiUrl}/admin_get_brand_models/${brandId}`);
}

/* Create model */

export function createModel(modelData) {
    return axios.post(`${apiUrl}/admin_create_auto_model`, modelData);
}

/* Update model */

export function updateModel(modelData) {
    return axios.post(`${apiUrl}/admin_update_auto_model`, modelData);
}

/* Delete */

export function deleteModel(modelData) {
    return axios.post(`${apiUrl}/admin_deletion_model`, modelData);
}