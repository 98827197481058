import React, {useEffect, useState} from "react";
import './AdminCities.scss';
import {Button, TextField} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import Link from "@material-ui/core/Link";
import {DataGrid, GridOverlay} from "@material-ui/data-grid";
import SearchIcon from "@material-ui/icons/Search";
import {
    useParams
} from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {deleteCity} from "../../../../services/city";
import {getCountryWithCities} from "../../../../services/country";
import CircularProgress from "@material-ui/core/CircularProgress";
import {DeleteDialog} from "../DeleteDialog/DeleteDialog";
import {setMessage, setOpen} from "../../../../reducers/snackbarReducer";
import {useDispatch} from "react-redux";
import CityDialogComponent from "./cityDialogComponent/cityDialogComponent";

export default function AdminCities()  {

    /* Refresher function */

    function refreshPage() {
        window.location.reload();
    }

    const dispatch = useDispatch();

    const togglePassword = ({ currentTarget }) => {
        currentTarget.classList.toggle('active');
        const passwordInput = document.getElementById('password');

        if (currentTarget.classList.contains('active')) {
            passwordInput.type = 'text';
        }
        else {
            passwordInput.type = 'password';
        }
    }

    /* Open the delete dialog */

    const [isOpenCity, setIsOpenCity] = useState(false);
    const [showCityDialog, setShowCityDialog] = useState(false);
    const [deletionDialogContent, setDeletionDialogContent] = useState("");
    const [submitFunction, setSubmitFunction] = useState(null);
    const [rowData, setRowData] = useState({});
    const [country, setCountry] = useState(undefined);
    const [cities, setCities] = useState([]);
    const [anchorEls, setAnchorEls] = useState([]);
    const [dataLoading, isDataLoading] = useState(true);

    const handleClickOpenDeleteCityDialog = (data) => {
        const cityDel = {
            "city_id": data.id_original
        }
        setSubmitFunction(() => ()=>{
            deleteCity(cityDel).then((data)=> {
                if (data.data.RESPONSECODE == 200) {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                    refreshPage();
                } else {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                }
            }).catch((e)=>{
                dispatch(setMessage({message : "Erreur lors de la suppression"}));
                dispatch(setOpen({open : true}));
                console.error(e);
            }).finally((e)=> {
                console.log(e);
            });
            handleClickCloseDeleteCityDialog();
        });
        setDeletionDialogContent("Êtes vous sûr de vouloir supprimer la ville " + data.city)
        setIsOpenCity(true);
    };

    const handleClickCloseDeleteCityDialog = () => {
        setIsOpenCity(false);
    };

    let params = useParams();
    let countryId = params.id;

    /* Cities each menu */

    const handleClick = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = event.target;
        setAnchorEls(aels);
    };

    const handleClose = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = null;
        setAnchorEls(aels);
    };

    /* Fetch all cities data */

    useEffect(()=>{
        getCountryWithCities(countryId).then(({data})=> {
            let res = [];
            let cities = data[0].cities;
            //console.log(data);
            cities.forEach((city)=> {
                res.push({
                    id: city.num_order,
                    id_original: city.id,
                    city: city.name,
                    slug: city.slug,
                    numOrder: city.num_order,
                    showAction: ''
                });
            })
            setCities(res);
            setCountry(data[0]);
            /*console.log(data[0]);*/
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
            isDataLoading(false);
        });
    },[])

    /* Update and add dialog */

    const openCityDialog = (data) => {
        setRowData(data);
        setShowCityDialog(true);
    }

    const closeCityDialog = () => {
        setRowData({});
        setShowCityDialog(false);
    }

    /* Cities data grid header infos */

    const columns = [
        /*{ field: 'id', headerName: 'ID', width: 70 },*/
        { field: 'numOrder', headerName: 'Numéro d\'ordre', width: 140 },
        { field: 'city', headerName: 'Ville', width: 250 },
        { field: 'slug', headerName: 'Slug', width: 100 },
        { field: 'showAction', headerName: 'Actions', width: 100,
            renderCell: (params) => (
                // params.rowModel
                <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={e => handleClick(params.rowModel.id, e)}>
                        Actions
                    </Button>
                    <Menu
                        id={params.rowModel.id}
                        anchorEl={anchorEls[params.rowModel.id]}
                        keepMounted
                        open={Boolean(anchorEls[params.rowModel.id])}
                        onClose={e => handleClose(params.rowModel.id, e)}
                    >
                        <Link href={`/dashboard/countries/cities/${params.rowModel.data.id_original}/zones`} onClick={e => handleClose(params.rowModel.id, e)}><VisibilityIcon/>Voir tous les quartiers</Link>
                        <MenuItem onClick={(e) => {
                            handleClose(params.rowModel.id, e);
                            openCityDialog(params.rowModel.data, e);
                        }}>
                            <CreateIcon/>Modifier</MenuItem>
                        <MenuItem onClick={(e) => {
                            handleClose(params.rowModel.id, e);
                            handleClickOpenDeleteCityDialog(params.rowModel.data);
                        }}>
                            <DeleteIcon/>Supprimer
                        </MenuItem>
                    </Menu>
                </div>
            ),},
    ];

    /* No data display */

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                { dataLoading ? <div className="data-loading"> <CircularProgress /> Chargement en cours... </div> : <div>Pas de données</div> }
            </GridOverlay>
        );
    }

    return (
        <div className="admin-content-wrapper cities">
            <div className="admin-content-wrapper-title">
                <IconButton href={`/dashboard/countries`}>
                    <ArrowBackIcon />
                </IconButton>
                <h2>Details pays</h2>
            </div>
            <div className="item-details">
                <div className="item-details-infos">
                    <ul>
                        <li>Pays :</li>
                        <li>{country ? country.name : " "}</li>
                    </ul>
                    <ul>
                        <li>Ordre :</li>
                        <li>{country ? country.num_order : " "}</li>
                    </ul>
                    <ul>
                        <li>Date de modification :</li>
                        <li>{country ? country.updated_at : " "}</li>
                    </ul>
                </div>
            </div>
            <div className="page-header">
                <div className="page-header-add">
                    <Button onClick={openCityDialog}>Ajouter une ville</Button>
                    <CityDialogComponent showDialog={showCityDialog} city={rowData} countryId={countryId} handleClose={closeCityDialog} />
                </div>
                <div className="page-header-search">
                    <TextField
                        /*label="Recherche"*/
                        placeholder="Recherche"
                        type="text"
                        variant="outlined"
                        className="search-field"
                        name="search"
                        id="search"
                        InputProps={{
                            startAdornment: <SearchIcon/>
                        }}
                    />
                    <Button>Chercher</Button>
                </div>
            </div>
            <div className="countires-list">
                <div className="grid-wrapper" style={{ display: 'block', width: '100%' }}>
                    <DataGrid
                        rows={cities}
                        columns={columns}
                        pageSize={10}
                        autoHeight={true}
                        disableSelectionOnClick={true}
                        components={{
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                    />
                </div>
            </div>
            <DeleteDialog message={deletionDialogContent} isOpen={isOpenCity} submit={submitFunction} handleClose={handleClickCloseDeleteCityDialog} />
        </div>
    );
}