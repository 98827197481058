import React, {useEffect, useState} from "react";
import './AdminModels.scss';
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button, TextField} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {DataGrid, GridOverlay} from "@material-ui/data-grid";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {useParams} from "react-router-dom";
import {createModel, getAllModels} from "../../../../services/model";
import {setMessage, setOpen} from "../../../../reducers/snackbarReducer";
import {useDispatch} from "react-redux";
import {DeleteDialog} from "../DeleteDialog/DeleteDialog";
import {deleteBrand} from "../../../../services/brand";
import ModelDialogComponent from "./modelDialogComponent/modelDialogComponent";
import BrandDialogComponent from "../Brands/brandDialogComponent/brandDialogComponent";

export default function AdminBrands() {

    /* Refresher function */

    function refreshPage() {
        window.location.reload();
    }

    const dispatch = useDispatch();

    let parameters = useParams();
    let brandId = parameters.id;

    console.log(parameters);

    /* Categories Each Menu view */

    const [anchorEls, setAnchorEls] = useState([]);
    /*const [anchorElMenu, setAnchorElMenu] = React.useState(null);*/
    const [brand, setBrand] = useState(undefined);
    const [models, setModels] = useState([]);
    const [dataLoading, isDataLoading] = useState(true);
    const [showModelDialog, setShowModelDialog] = useState(false);
    const [isOpenModel, setIsOpenModel] = useState(false);
    const [deletionDialogContent, setDeletionDialogContent] = useState("");
    const [submitFunction, setSubmitFunction] = useState(null);
    const [rowData, setRowData] = useState({});

    const handleClick = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = event.target;
        setAnchorEls(aels);
        //  setAnchorEl(event.currentTarget);
    };

    const handleClose = (id, event) => {
        // setAnchorEl(null);
        let aels = anchorEls.slice();
        aels[id] = null;
        setAnchorEls(aels);
    };

    const handleClickOpenDeleteModelDialog = (data) => {
        //console.log(data);
        const modelDel = {
            "model_id": data.id_original
        }
        setSubmitFunction(() => ()=>{
            deleteBrand(modelDel).then((data)=> {
                if (data.data.RESPONSECODE == 200) {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                    refreshPage();
                } else {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                }
            }).catch((e)=>{
                dispatch(setMessage({message : "Erreur lors de la suppression"}));
                dispatch(setOpen({open : true}));
                console.error(e);
            }).finally((e)=> {
                console.log(e);
            });
            handleClickCloseDeleteModelDialog();
        });
        setDeletionDialogContent("Êtes vous sûr de vouloir supprimer le modèle " + data.model)
        setIsOpenModel(true);
    };

    const handleClickCloseDeleteModelDialog = () => {
        setIsOpenModel(false);
    };

    /* Fetch all models data */

    useEffect(()=>{
        getAllModels(brandId).then(({data})=>{
            let res = [];
            //console.log(data);
            let models = data[0].models;
            models.forEach((model)=> {
                res.push({
                    id: model.num_order,
                    id_original: model.id,
                    model: model.name,
                    numOrder: model.num_order,
                    showAction: ''
                });
            })
            setModels(res);
            setBrand(data[0]);
            console.log(brand);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=>{
            console.log(e);
            isDataLoading(false);
        })
    },[])

    /* Update and add dialog */

    const openModelDialog = (data) => {
        setRowData(data);
        setShowModelDialog(true);
    }

    const closeModelDialog = () => {
        setRowData({});
        setShowModelDialog(false);
    }

    /* Data Grid models infos */

    const columns = [
        /*{field: 'id', headerName: 'ID', width: 70},*/
        { field: 'numOrder', headerName: 'Numéro d\'ordre', width: 140 },
        {field: 'model', headerName: 'Modèle', width: 300},
        {field: 'showAction', headerName: 'Actions', width: 100,
            renderCell: (params) => (
                <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true"
                            onClick={e => handleClick(params.rowModel.id, e)}>
                        Actions
                    </Button>
                    <Menu
                        id={params.rowModel.id}
                        anchorEl={anchorEls[params.rowModel.id]}
                        keepMounted
                        open={Boolean(anchorEls[params.rowModel.id])}
                        onClose={e => handleClose(params.rowModel.id, e)}
                    >
                        <MenuItem onClick={(e) => {
                            handleClose(params.rowModel.id, e);
                            openModelDialog(params.rowModel.data);
                        }}>
                            <CreateIcon/>Modifier
                        </MenuItem>
                        <MenuItem onClick={(e) => {
                            handleClose(params.rowModel.id, e);
                            handleClickOpenDeleteModelDialog(params.rowModel.data);
                        }}>
                            <DeleteIcon/>Supprimer
                        </MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                { dataLoading ? <div className="data-loading"> <CircularProgress /> Chargement en cours... </div> : <div>Pas de données</div> }
            </GridOverlay>
        );
    }

    return (
        <div className="models">
            <div className="admin-content-wrapper-title">
                <IconButton href={`/dashboard/brands/${parameters.type}/${parameters.brandName}`}>
                    <ArrowBackIcon/>
                </IconButton>
                <h2>Details de la marque</h2>
            </div>
            <div className="item-details">
                <div className="item-details-infos">
                    <ul>
                        <li>Marque : </li>
                        {brand && <li>{brand.name}</li>}
                    </ul>
                    <ul>
                        <li>Ordre :</li>
                        {brand && <li>{brand.num_order}</li>}
                    </ul>
                    <ul>
                        <li>Date de modification :</li>
                        {brand && <li>{brand.updated_at}</li>}
                    </ul>
                </div>
            </div>
            <div className="page-header">
                <div className="page-header-add">
                    <Button onClick={openModelDialog}>Ajouter un modèle</Button>
                    <ModelDialogComponent showDialog={showModelDialog} model={rowData} brandId={brandId} brandType={parameters.type} handleClose={closeModelDialog} />
                </div>
                <div className="page-header-search">
                    <TextField
                        /*label="Recherche"*/
                        placeholder="Recherche"
                        type="text"
                        variant="outlined"
                        className="search-field"
                        name="search"
                        id="search"
                        InputProps={{
                            startAdornment: <SearchIcon/>
                        }}
                    />
                    <Button>Chercher</Button>
                </div>
            </div>
            <div className="model-list">
                <div className="grid-wrapper" style={{display: 'block', width: '100%'}}>
                    <DataGrid
                        rows={models}
                        columns={columns}
                        pageSize={10}
                        autoHeight={true}
                        disableSelectionOnClick={true}
                        components={{
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                    />
                </div>
            </div>
            <DeleteDialog message={deletionDialogContent} isOpen={isOpenModel} submit={submitFunction} handleClose={handleClickCloseDeleteModelDialog} />
        </div>
    );
};