import axios from 'axios';
import {
    apiUrl,
} from '../utils/axios';

export function getAllBrands(brandData) {
    return axios.post(`${apiUrl}/admin_get_auto_brands`, brandData);
}

/* Create brand */

export function createBrand(brandData) {
    return axios.post(`${apiUrl}/admin_create_auto_brand`, brandData);
}

/* Update brand */

export function updateBrand(brandData) {
    return axios.post(`${apiUrl}/admin_update_auto_brand`, brandData);
}

/* Delete */

export function deleteBrand(brandData) {
    return axios.post(`${apiUrl}/admin_deletion_model`, brandData);
}