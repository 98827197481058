import axios from 'axios';
import {
    apiUrl,
} from '../utils/axios';

export function getAllSubCategories(categoryId) {
    return axios.get(`${apiUrl}/admin_get_category_sub_categories/${categoryId}`);
}

/* Create sub category */

export function createSubCategory(subCategoryData) {
    return axios.post(`${apiUrl}/admin_create_subcategory`, subCategoryData);
}

/* Update sub category */

export function updateSubCategory(subCategoryData) {
    return axios.post(`${apiUrl}/admin_update_subcategory`, subCategoryData);
}

/* Delete category */

export function deleteSubCategory(subCategoryData){
    return axios.post(`${apiUrl}/admin_deletion_category`, subCategoryData);
}
