import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import stores from './store/index';

/* Global style */
import './global.scss';

import App from './containers/App';
import SnackBarComponent from "./containers/SnackbarComponent";
import Fonts from './fonts';
import * as serviceWorker from './serviceWorker';


//if (process.env.NODE_ENV === "development") {
 // makeServer({ environment: "development" })
//}


ReactDOM.render(
    <Provider store={stores.store}>
      <PersistGate persistor={stores.persistor}>
        <Router>
          <SnackBarComponent />
          <Fonts />
          <App />
        </Router>
      </PersistGate>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
