import axios from 'axios';
import {
    apiUrl,
} from '../utils/axios';

export function getAllColors() {
    return axios.get(`${apiUrl}/admin_get_colors`);
}

export function createColor(colorData) {
    return axios.post(`${apiUrl}/admin_create_color`, colorData);
}

export function updateColor(colorData) {
    return axios.post(`${apiUrl}/admin_update_color`, colorData);
}

export function deleteColor(colorData) {
    return axios.post(`${apiUrl}/admin_deletion_color`, colorData);
}