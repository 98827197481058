import React, {useEffect} from "react";
import './AdminMembers.scss';
// import {DataGrid, GridOverlay} from "@material-ui/data-grid";
import { DataGrid, useGridApiContext, useGridState, GridOverlay } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import { makeStyles } from '@mui/styles';
import {Button, TextField} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import Link from "@material-ui/core/Link";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getAllMembers, suspendMember} from "../../../../services/member";
import {useSelector} from "react-redux";

const useStyles = makeStyles({
    root: {
      display: 'flex',
    },
  });

export default function AdminMembers()  {

    /* Member Each Menu view */

    let currentCountry = useSelector(state => state.country.currentCountry);
    const [anchorEls, setAnchorEls] = React.useState([]);
    const [members, setMembers] = React.useState([]);
    const [dataLoading, isDataLoading] = React.useState(true);
    const [page, setPage] = React.useState(0);
    const [rowCount, setRowCount] = React.useState(0);

    /* Actions constantes declared */

    const handleClick = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = event.target;
        setAnchorEls(aels);
    };

    const handleClose = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = null;
        setAnchorEls(aels);
    };

    const handlePageChange = (page) => {
        console.warn(page);
        setPage(page * 10);
        isDataLoading(true);
        handlePagination()
    };

    // ** Function to handle Pagination and get data
    const handlePagination = () => {

        const obj = {
            offset: page,
            limit: 10,
            country_id:  currentCountry ? currentCountry.id : ''
        };
        getAllMembers(obj).then(({data})=> {
            let res = [];
            let membersData = data.RESPONSEDATA;
            console.warn(membersData);
            membersData.forEach((member)=> {
                res.push({
                    id: member.id,
                    fullName: member.last_name ? `${member.first_name} ${member.last_name}` : member.first_name,
                    memberType: member.type== 1 ? 'Particulier' : 'Professionnel',
                    rs: member.pro_rs != null ? member.pro_rs : '-',
                    // city: member.city_id != null ? member.city_id : '-',
                    date: member.created_at != null ? member.created_at : '-' ,
                    suspended: member.suspended == 0 ? 'Non' : 'Oui',
                    showAction: ''
                });
            })
            setMembers(res);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
            isDataLoading(false);
        });
    }

    const CustomPagination = () => {
        const apiRef = useGridApiContext();
        const [state] = useGridState(apiRef);
        const classes = useStyles();
    
        return (
            <Pagination
                className={classes.root}
                color="primary"
                count={state.pagination.pageCount}
                page={state.pagination.page + 1}
                onChange={(event, value) => {apiRef.current.setPage(value - 1); handlePageChange(value)}}
            />
        );
    }

    useEffect(()=>{
        handlePagination()
    },[]);

    /* Data Grid header infos */

    const columns = [
        /*{ field: 'id', headerName: 'ID', width: 70 },*/
        { field: 'fullName', headerName: 'Nom', width: 240 },
        { field: 'memberType', headerName: 'Type', width: 120 },
        { field: 'rs', headerName: 'Raison sociale', width: 150 },
        { field: 'date', headerName: 'Date', width: 200 },
        { field: 'suspended', headerName: 'Suspendu', width: 120 },
        { field: 'showAction', headerName: 'Actions', width: 100,
            renderCell: (params) => (
                <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={e => handleClick(params.id, e)}>
                        Actions
                    </Button>
                    <Menu
                        id={params.id}
                        anchorEl={anchorEls[params.id]}
                        keepMounted
                        open={Boolean(anchorEls[params.id])}
                        onClose={e => handleClose(params.id, e)}
                    >
                        <Link href={`/dashboard/members/${params.id}/utilisateur`} onClick={e => handleClose(params.id, e)}>
                            <VisibilityIcon/>Voir
                        </Link>
                        <MenuItem onClick={e => {
                            handleClose(params.id, e)
                            suspendUser(params.id)
                        }}>
                            <RemoveCircleOutlinedIcon/>Suspendre
                        </MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    /* No data display */

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                {
                    dataLoading ?
                        <div className="data-loading"> <CircularProgress /> Chargement en cours... </div>
                        : <div>Pas de données</div>
                }
            </GridOverlay>
        );
    }

    const suspendUser = (member_id) => {
        const data = {
            user_id: member_id
        }
        suspendMember(data).then(result => {
            window.location.reload();
        }).catch(error => {
            console.warn(error);
        });
    }
    return (
        <div className="members-list">
            <h1>Membres</h1>
            <div className="page-header">
                <div className="page-header-search">
                    <TextField
                        /*label="Recherche"*/
                        placeholder="Recherche"
                        type="text"
                        variant="outlined"
                        className="search-field"
                        name="search"
                        id="search"
                        InputProps={{
                            startAdornment: <SearchIcon/>
                        }}
                    />
                    <Button>Chercher</Button>
                </div>
            </div>
            <div className="grid-wrapper" style={{ }}>
                {/* <DataGrid
                    rows={members}
                    columns={columns}
                    pageSize={10}
                    rowCount={100}
                    autoHeight={true}
                    disableSelectionOnClick={true}
                    paginationMode="server"
                    components={{
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    onPageChange={(newPage) => handlePageChange(newPage)}
                    loading={dataLoading}
                /> */}
                <DataGrid
                    rows={members}
                    columns={columns}
                    pagination
                    paginationMode="server"
                    pageSize={10}
                    components={{
                        noRowsOverlay: CustomNoRowsOverlay,
                        Pagination: CustomPagination,
                    }}
                    disableColumnMenu
                    rowCount={100}
                    loading={dataLoading}
                    />
            </div>
        </div>
    );
}
