import React, {useEffect, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, TextField} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import {useFormik} from "formik";
import * as Yup from "yup";
import {setMessage, setOpen} from "../../../../../../reducers/snackbarReducer";
import {useDispatch} from "react-redux";
import Icon from "@iconify/react";
import cameraPlusOutline from "@iconify/icons-mdi/camera-plus-outline";
import {createIcon, updateIcon} from "../../../../../../services/icon";

export default function IconDialogComponent ({showDialog, icone, handleCloseIcon}) {

    /* Refresher function */

    function refreshPage() {
        window.location.reload();
    }

    const dispatch = useDispatch();

    const [picture, setPicture] = useState(undefined);
    const fileInput = React.createRef();

    const FILE_SIZE = 160 * 1024;
    const SUPPORTED_FORMATS = [
        "image/*",
        "image/jpeg",
        "image/gif",
        "image/png",
        "image/svg+xml"
    ];

    const updatePicture = (url) => {
        setPicture(url);
    }

    const handleChange = (e) => {
        let file = (fileInput.current.files[0]);
        if(file){
            let reader = new FileReader();
            reader.onload = function(e) {
                updatePicture(e.target.result);
            };
            reader.readAsDataURL(file);
            formik.setFieldValue(e.target.name, file);
        }
    }

    /* Add or update a new country */

    let formik = useFormik({
        initialValues: {
            name: undefined,
            file: undefined,
            published: icone.published,
            changed_image: 0
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required('Ce champ est requis'),
            file: Yup.lazy(() => {
                    return (icone.iconImage && !picture) ?
                        Yup.mixed() :
                        Yup.mixed()
                            .required("Ce champ est requis")
                            .test(
                                "fileSize",
                                "Ce fichier est trop volumineux",
                                value => value && value.size <= FILE_SIZE
                            )
                            .test(
                                "fileFormat",
                                "Ce format n'est pas supporté",
                                value => value && SUPPORTED_FORMATS.includes(value.type)
                            )
                }
            ),
        }),
        onSubmit: (values, actions) => {
            //let iconInfos = { ...values, images : [picture]};
            let iconInfos = {...values};
            delete iconInfos.file;
            if (picture) {
                iconInfos.images = [picture];
                iconInfos.changed_image = 1;
            }
            iconInfos.published = iconInfos.published == false ? 0 : 1;
            if (icone.id) {
                iconInfos.icon_id = icone.id;
                //console.log(JSON.stringify(iconInfos, null, 2));
                updateIcon(iconInfos).then(({data})=>{
                    //console.log(data);
                    dispatch(setMessage({message : "Icone créee avec succès"}));
                    dispatch(setOpen({open : true}));
                    formik.resetForm();
                }).catch((e)=> {
                    dispatch(setMessage({message : "Erreur lors de la création"}));
                    dispatch(setOpen({open : true}));
                    console.error(e);
                }).finally((e)=> {
                    console.log(e);
                    actions.setSubmitting(false);
                    refreshPage();
                })
            } else {
                delete iconInfos.changed_image;
                console.log(JSON.stringify(iconInfos, null, 2));
                createIcon(iconInfos).then(({data}) => {
                    //console.log(data);
                    dispatch(setMessage({message : "Icone créee avec succès"}));
                    dispatch(setOpen({open : true}));
                    formik.resetForm();
                }).catch((e)=> {
                    dispatch(setMessage({message : "Erreur lors de la création"}));
                    dispatch(setOpen({open : true}));
                    console.error(e);
                }).finally((e)=> {
                    console.log(e);
                    actions.setSubmitting(false);
                    refreshPage();
                })
            }
        },
    });

    useEffect(()=> {
        if (icone.id) {
            formik.values.name = icone.iconName;
        } else {
            formik.values.name = undefined;
            formik.values.file = undefined;
        }
    }, [icone]);

    return (
        <div className="icon-dialog">
            <Dialog
                className="dialog large"
                open = {showDialog}
                onClose={handleCloseIcon}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <IconButton aria-label="close" className="closeButton" onClick={handleCloseIcon}>
                    <MdClose />
                </IconButton>
                <div className="change-container custom-modal">
                    <DialogTitle id="dialog-title">{ icone.id ? "Modification d'une icone" : "Création d'un icone" }</DialogTitle>
                    <DialogContent>
                        <div className="dialog-container">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="full-form-wrapper">
                                    <TextField
                                        name="name"
                                        label="Nom de l'icone"
                                        type="text"
                                        variant="outlined"
                                        className={"name-field" + (formik.errors.name && formik.touched.name ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={icone.iconName}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <p className="errorMessage">{formik.errors.name}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper picture">
                                    <div className="picture-choose">
                                        <input
                                            type="file"
                                            id="imageUpload"
                                            accept=".png, .jpg, .jpeg, .gif"
                                            ref={fileInput}
                                            name="file"
                                            onChange={(e)=>{
                                                handleChange(e);
                                            }}
                                            onBlur={formik.handleBlur}
                                            style={{visibility : (!picture && !icone.iconImage) ? "visible" : "hidden" }}
                                        />
                                        <label htmlFor="imageUpload">
                                            <Icon icon={cameraPlusOutline}/>
                                            <span>Ajouter une image</span>
                                        </label>
                                    </div>
                                    {
                                        picture ? <div className="picture-default"><img src={picture} /></div>
                                            : ( icone.iconImage != undefined ? <div className="picture-default"><img src={icone.iconImage} /></div> : '')
                                    }

                                    {formik.touched.file && formik.errors.file ? (
                                        <p className="errorMessage">{formik.errors.file}</p>) : null
                                    }
                                </div>
                                <div className="full-form-wrapper published">
                                    <FormControlLabel
                                        control={
                                            <div>
                                                <Checkbox
                                                    name="published"
                                                    color="secondary"
                                                    id="published"
                                                    defaultChecked={true}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.published}
                                                />
                                            </div>
                                        }
                                        label={<label htmlFor="published">Publier</label>} />
                                </div>
                            </form>
                        </div>
                    </DialogContent>
                    <DialogActions className="confirmation-button">
                        <Button onClick={()=>{
                            setPicture(undefined);
                            formik.resetForm();
                            handleCloseIcon();
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button type="submit" onClick={formik.handleSubmit} disabled={formik.isSubmitting} color="primary">
                            {icone.id ? "Modifier" : "Créer" }
                            {
                                formik.isSubmitting ?
                                    (<CircularProgress />) : ('')
                            }
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}