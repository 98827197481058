import React, {useEffect, useState} from "react";
import './AdminAdvertDetails.scss';
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import defaultImage from "../../../../../imgs/default.png";
import Link from "@material-ui/core/Link";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CheckIcon from '@material-ui/icons/Check';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from '@material-ui/icons/Clear';
import {DeleteDialog} from "../../DeleteDialog/DeleteDialog";
import {useParams} from "react-router-dom";
import {getAdvertByID} from "../../../../../services/advert";
import {staticApiUrl} from '../../../../../utils/axios';

export default function AdminAdvertDetails() {

    let params = useParams();
    let advertID = params.id;

    const [anchorElMenu, setAnchorElMenu] = React.useState(null);
    const [advert, setAdvert] = React.useState([]);

    const openMenu = Boolean(anchorElMenu);

    const handleClickMenu = (event) => {
        setAnchorElMenu(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorElMenu(null);
    };

    /* Open the delete dialog */

    const [isOpenMember, setIsOpenMember] = useState(false);

    const handleClickOpenDeleteMemberDialog = () => {
        setIsOpenMember(true);
    };

    const handleClickCloseDeleteMemberDialog = () => {
        setIsOpenMember(false);
    };

    /* Get advert by id */

    let url = `${staticApiUrl}/adimages/`;

    useEffect(()=>{
        const advertInfos = {
            "advert_id": advertID
        }
        getAdvertByID(advertInfos).then(({data})=> {
            //console.log(data);
            if (data.STATUS === true) {
                //console.log(data.RESPONSEDATA[0]);
                /*let filename = "no-image";
                if (data.RESPONSEDATA[0].images[0] !== undefined) {
                    filename = data.RESPONSEDATA[0].images[0].filename;
                }
                let advertImage = url + filename;
                console.log(advertImage);*/
                setAdvert(data.RESPONSEDATA[0]);
                //console.log(advert);
            } else {
                console.log("Pas de données");
            }
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
        });
    },[])

    function checkType(advertTypeNumber) {
        //console.log(advertTypeNumber);
        switch (advertTypeNumber) {
            case '1':
                return ("Offre")
            case '2':
                return ("Je recherche")
            case '3':
                return ("Je troc")
            case '4':
                return ("Objet gratuit")
            default:
                return ("Annonce non trouvée.")
        }
    }

    function checkTypePrice(advertTypePrice) {
        //console.log(advertTypePrice);
        switch (advertTypePrice) {
            case '1':
                return ("De l'heure")
            case '2':
                return ("Jour")
            case '3':
                return ("A la semaine")
            case '4':
                return ("Au mois")
            case '5':
                return ("Sur devis")
            default:
                return ("Type de tarification non trouvée.")
        }
    }

    return (
        <div className="advert-details">
            <div className="item-details">
                <div className="admin-content-wrapper-title">
                    <div className="admin-content-wrapper-title-group">
                        <IconButton href={`/dashboard/adverts`}>
                            <ArrowBackIcon />
                        </IconButton>
                        <h2>Détails annonce</h2>
                    </div>
                </div>
                <div className="item-details-menu">
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClickMenu}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorElMenu}
                        keepMounted
                        open={openMenu}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem onClick={handleCloseMenu}>
                            <CreateIcon/>Modifier
                        </MenuItem>
                        <MenuItem onClick={handleCloseMenu}>
                            <CheckIcon/>Accepter
                        </MenuItem>
                        <MenuItem onClick={handleCloseMenu}>
                            <ClearIcon/>Réfuser
                        </MenuItem>
                        <MenuItem onClick={() => {
                            handleCloseMenu();
                            handleClickOpenDeleteMemberDialog();
                        }}>
                            <DeleteIcon/>Supprimer
                        </MenuItem>
                        <DeleteDialog value="Pays" isOpen={isOpenMember} handleClose={handleClickCloseDeleteMemberDialog} />
                    </Menu>
                </div>
            </div>
            <div className="advert-details-content">
                <div className="advert-details-content-title">
                    <h2>{advert ? advert.title : ""}</h2>
                </div>
                <div className="advert-details-content-image">
                    {
                        advert ? (<img src={url} alt="user"/>) : (<img src={defaultImage} alt="user"/>)
                    }
                </div>
                <div className="advert-details-content-infos">
                    <div className="advert-details-content-infos-description">
                        <ul>
                            <li>Description : </li>
                            <li>{advert ? advert.description : ""}</li>
                        </ul>
                    </div>
                    <div className="advert-details-content-infos-others">
                        <ul className="advert-details-content-infos-others-item">
                            <li>ID : </li>
                            <li>{advert ? advert.advert_id : ""}</li>
                        </ul>
                        <ul className="advert-details-content-infos-others-item">
                            <li>Lien : </li>
                            <li>
                                <Link href={`/advertisements/${advert ? advert.title : ""}/${advert ? advert.advert_id : ""}`}>{advert ? advert.title : ""}</Link>
                            </li>
                        </ul>
                        <ul className="advert-details-content-infos-others-item">
                            <li>Adresse IP : </li>
                            <li>{advert ? advert.ip_address : ""}</li>
                        </ul>
                        <ul className="advert-details-content-infos-others-item">
                            <li>Membre : </li>
                            <li>
                                <Link href={`/dashboard/members/${advert ? advert.user_id : ""}/utilisateur`}>
                                    {advert ? advert.last_name : ""} {advert ? advert.first_name : ""}
                                </Link>
                            </li>
                        </ul>
                        <ul className="advert-details-content-infos-others-item">
                            <li>Type d'annonce : </li>
                            <li>{advert ? checkType(advert.type_advert) : ""}</li>
                        </ul>
                        <ul className="advert-details-content-infos-others-item">
                            <li>Catégorie : </li>
                            <li>
                                <span>{advert ? advert.category : ""}</span>
                            </li>
                        </ul>
                        {
                            (advert.type_advert == 1|| advert.type_advert == 2) ?
                                (
                                    <ul className="advert-details-content-infos-others-item">
                                        <li>Prix : </li>
                                        <li>{advert ? advert.price : ""}</li>
                                    </ul>
                                ) : ("")
                        }
                        {
                            (advert.type_advert == 1|| advert.type_advert == 2) ?
                                (
                                    <ul className="advert-details-content-infos-others-item">
                                        <li>Négociable : </li>
                                        <li>{advert ? (advert.negotiable == 0 ? 'Non' : 'Oui') : ""}</li>
                                    </ul>
                                ) : ("")
                        }
                        <ul className="advert-details-content-infos-others-item">
                            <li>Type de tarification : </li>
                            <li>{advert ? checkTypePrice(advert.type_price) : ""}</li>
                        </ul>
                        <ul className="advert-details-content-infos-others-item">
                            <li>Ville ou quartier : </li>
                            <li>{advert ? advert.city : ""}</li>
                        </ul>
                        {/* Auto block */}
                        {
                            (
                                advert.category == "AUTOS ET MOTOS" ||
                                advert.category == "Voitures" ||
                                advert.category == "Motos" ||
                                advert.category == "Location voiture" ||
                                advert.category == "Pièces détachées et Accessoires" ||
                                advert.category == "Engins de chantier" ||
                                advert.category == "Camions" ||
                                advert.category == "Bateaux" ||
                                advert.category == "Vélos" ||
                                advert.category == "Autres"
                            ) ?
                                (
                                    <span className="block-wrapper">
                                        <ul className="advert-details-content-infos-others-item">
                                            <li>Marque : </li>
                                            <li>{advert ? advert.brand_id : ""}</li>
                                        </ul>
                                        <ul className="advert-details-content-infos-others-item">
                                            <li>Modèle : </li>
                                            <li>{advert ? advert.model_id : ""}</li>
                                        </ul>
                                        <ul className="advert-details-content-infos-others-item">
                                            <li>Année : </li>
                                            <li>{advert ? advert.year : ""}</li>
                                        </ul>
                                        <ul className="advert-details-content-infos-others-item">
                                            <li>Kilométrage : </li>
                                            <li>{advert ? advert.mileage : ""}</li>
                                        </ul>
                                        <ul className="advert-details-content-infos-others-item">
                                            <li>Nombre de portes : </li>
                                            <li>{advert ? advert.nb_door : ""}</li>
                                        </ul>
                                        <ul className="advert-details-content-infos-others-item">
                                            <li>Nombre de places : </li>
                                            <li>{advert ? advert.nb_seat : ""}</li>
                                        </ul>
                                        <ul className="advert-details-content-infos-others-item">
                                            <li>Couleur : </li>
                                            <li>{advert ? advert.color : ""}</li>
                                        </ul>
                                        <ul className="advert-details-content-infos-others-item">
                                            <li>Carburant : </li>
                                            <li>{advert ? advert.fuel : ""}</li>
                                        </ul>
                                        <ul className="advert-details-content-infos-others-item">
                                            <li>Boite de vitesse : </li>
                                            <li>{advert ? advert.gear_box : ""}</li>
                                        </ul>
                                    </span>
                                ) :
                                ("")
                        }
                        {/* Estate block */}
                        {
                            (
                                advert.category == "IMMOBILIER" ||
                                advert.category == "Appartements" ||
                                advert.category == "Maisons et Villas" ||
                                advert.category == "Bureaux et Plateaux" ||
                                advert.category == "Magasins, Commerces, et Locaux industriels" ||
                                advert.category == "Terrains et Fermes" ||
                                advert.category == "Locations de vacances" ||
                                advert.category == "Colocations" ||
                                advert.category == "Autres immobiliers"

                            ) ?
                                (
                                    <span class="block-wrapper">
                                        <ul className="advert-details-content-infos-others-item">
                                            <li>Surface(m²) : </li>
                                            <li>{advert ? advert.area : ""} </li>
                                        </ul>
                                        <ul className="advert-details-content-infos-others-item">
                                            <li>Nombre de pièces : </li>
                                            <li>{advert ? advert.nb_rooms : ""}</li>
                                        </ul>
                                    </span>
                                ) :
                                ("")
                        }
                    </div>
                    {/*Others infos*/}
                    <div className="advert-details-content-infos-others system">
                        {/*<ul className="advert-details-content-infos-others-item">
                            <li>Status : </li>
                            <li>En ligne</li>
                        </ul>*/}
                        <ul className="advert-details-content-infos-others-item">
                            <li>Date de création : </li>
                            <li>{advert ? advert.created_at : ""}</li>
                        </ul>
                        {/*<ul className="advert-details-content-infos-item">
                        <li>Publié : </li>
                        <li>{advert ? advert.created_at : ""}</li>
                    </ul>
                    <ul className="advert-details-content-infos-item">
                        <li>Date de modification : </li>
                        <li>12/12/2030 12:30:45</li>
                    </ul>*/}
                        <ul className="advert-details-content-infos-others-item">
                            <li>Vues : </li>
                            <li>{advert ? advert.views : ""}</li>
                        </ul>
                        {/*<ul className="advert-details-content-infos-item">
                        <li>Contacts : </li>
                        <li>0</li>
                    </ul>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}