/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from "@material-ui/core/IconButton";
import './SnackBar.scss';
import Slide from '@material-ui/core/Slide';

const SnackBarComponent = ({ open, message, setOpen, setMessage }) => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message={message}
                TransitionComponent={Slide}
                action={
                    <React.Fragment>
                        {/*<Icon icon={bxCheckCircle} />*/}
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
            />
    );
};

export default SnackBarComponent;
