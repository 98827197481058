import {
  saveAllCategories,
} from "../reducers/categoryReducer";

import {
  getAllCategories,
} from '../services/category'

const logMiddleware = (store) => (next) => (action) => {

    switch (action.type) {
      case 'category/getAllCategories':
        getAllCategories().then(({ data }) => {
          let { categories } = data;
          store.dispatch(saveAllCategories({ categories : categories }));
        }).catch((e)=>{
          console.error(e);
        })
        break;

      default:
        break;
    }
  
  next(action);
};

export default logMiddleware;
