import React, {useEffect} from "react";
import IconButton from "@material-ui/core/IconButton";
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, TextField} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import {useFormik} from "formik";
import * as Yup from "yup";
import {setMessage, setOpen} from "../../../../../reducers/snackbarReducer";
import {useDispatch} from "react-redux";
import {createZone, updateZone} from "../../../../../services/zone";

export default function ZoneDialogComponent ({showDialog, zone, handleClose, cityId}) {

    /* Refresher function */

    function refreshPage() {
        window.location.reload();
    }

    const dispatch = useDispatch();

    /* Add or update a new city */

    let formik = useFormik({
        initialValues: {
            name: undefined,
            slug: undefined,
            city_id: cityId,
            zone_id: zone.id_original,
            num_order: undefined,
            published: 1
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required('Ce champ est requis'),
            slug: Yup.string()
                .required('Ce champ est requis'),
            num_order: Yup.string()
                .required('Ce champ est requis')
        }),
        onSubmit: (values, actions) => {
            let zoneInfos = {...values};
            zoneInfos.published = zoneInfos.published == false ? 0 : 1;
            if (zone.id_original) {
                //console.log(zoneInfos);
                updateZone(zoneInfos).then(({data})=>{
                    dispatch(setMessage({message : "Quartier modifiée avec succès"}));
                    dispatch(setOpen({open : true}));
                    formik.resetForm();
                }).catch((e)=>{
                    dispatch(setMessage({message : "Erreur lors de la création"}));
                    dispatch(setOpen({open : true}));
                    console.error(e);
                }).finally((e)=>{
                    console.log(e);
                    actions.setSubmitting(false);
                    refreshPage();
                })
            } else {
                delete zoneInfos.zone_id;
                console.log(zoneInfos);
                createZone(zoneInfos).then(({data})=>{
                    dispatch(setMessage({message : "Quartier crée avec succès"}));
                    dispatch(setOpen({open : true}));
                    formik.resetForm();
                }).catch((e)=>{
                    dispatch(setMessage({message : "Erreur lors de la création"}));
                    dispatch(setOpen({open : true}));
                    console.error(e);
                }).finally((e)=>{
                    console.log(e);
                    actions.setSubmitting(false);
                    refreshPage();
                })
            }
        },
    })

    useEffect(()=>{
        if(zone.id){
            formik.values.name = zone.zone;
            formik.values.slug = zone.slug;
            formik.values.num_order = zone.numOrder;
            formik.values.zone_id = zone.id_original;
        }else {
            formik.values.name = undefined;
            formik.values.slug = undefined;
            formik.values.num_order = undefined;
            formik.values.zone_id = undefined;
        }
    }, [zone]);

    return (
        <div className="city-dialog">
            <Dialog
                className="dialog large"
                open={showDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <IconButton aria-label="close" className="closeButton" onClick={handleClose}>
                    <MdClose />
                </IconButton>
                <div className="change-container custom-modal">
                    <DialogTitle id="dialog-title">{ zone.id ? "Modification d'un quartier" : "Création d'un quartier" }</DialogTitle>
                    <DialogContent>
                        <div className="dialog-container">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="full-form-wrapper">
                                    <TextField
                                        name="name"
                                        label="Nom du quartier"
                                        type="text"
                                        variant="outlined"
                                        className={"name-field" + (formik.errors.name && formik.touched.name ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={zone.zone}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <p className="errorMessage">{formik.errors.name}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper">
                                    <TextField
                                        name="slug"
                                        label="Slug"
                                        type="text"
                                        variant="outlined"
                                        className={"num-field" + (formik.errors.slug && formik.touched.slug ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={zone.slug}
                                    />
                                    {formik.touched.slug && formik.errors.slug ? (
                                        <p className="errorMessage">{formik.errors.slug}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper">
                                    <TextField
                                        name="num_order"
                                        label="Ajouter le numéro d'ordre"
                                        type="number"
                                        variant="outlined"
                                        className={"num-field" + (formik.errors.num_order && formik.touched.num_order ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={zone.numOrder}
                                    />
                                    {formik.touched.num_order && formik.errors.num_order ? (
                                        <p className="errorMessage">{formik.errors.num_order}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper published">
                                    <FormControlLabel
                                        control={
                                            <div>
                                                <Checkbox
                                                    name="published"
                                                    color="secondary"
                                                    id="published"
                                                    defaultChecked={true}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.published}
                                                />
                                            </div>
                                        }
                                        label={<label htmlFor="published">Publier</label>} />
                                </div>
                            </form>
                        </div>
                    </DialogContent>
                    <DialogActions className="confirmation-button">
                        <Button onClick={() => {
                            formik.resetForm()
                            handleClose();
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button type="submit" onClick={formik.handleSubmit} disabled={formik.isSubmitting} color="primary">
                            {zone.id ? "Modifier" : "Créer" }
                            {
                                formik.isSubmitting ?
                                    (<CircularProgress />) : ('')
                            }
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}