import { connect } from 'react-redux';
import { setOpen, setMessage } from '../reducers/snackbarReducer';
import SnackBarComponent from "../components/Snackbar/SnackBarComponent";

const mapStateToProps = state => ({
  open: state.snackbar.open,
  message: state.snackbar.message,
});

const mapDispatchToProps = dispatch => ({
  setOpen: (open) => {
    dispatch(setOpen({ open : open}));
  },
  setMessage: (message) => {
    dispatch(setMessage({ message : message}));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SnackBarComponent);
