import React, {useEffect, useState} from "react";
import './AdminCategories.scss';
import {Button, TextField} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import Link from "@material-ui/core/Link";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MenuItem from "@material-ui/core/MenuItem";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {DataGrid, GridOverlay} from "@material-ui/data-grid";
import SearchIcon from "@material-ui/icons/Search";
import {getAllCategories, createCategory, deleteCategory} from "../../../../services/category";
import {useFormik} from "formik";
import * as Yup from "yup";
import {deleteIcon, getAllIcons} from "../../../../services/icon";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useDispatch} from "react-redux";
import {setMessage, setOpen} from "../../../../reducers/snackbarReducer";
import {DeleteDialog} from "../DeleteDialog/DeleteDialog";
import {staticApiUrl} from '../../../../utils/axios';

export default function AdminCategories () {

    /* Refresher function */

    function refreshPage() {
        window.location.reload();
    }

    let url = `${staticApiUrl}/iconimages/`;

    const dispatch = useDispatch();

    /* Categories Each Menu view */

    const [anchorEls, setAnchorEls] = React.useState([]);
    const [categories, setCategories] = React.useState([]);
    const [checked, setChecked] = React.useState(true);
    const [dataLoading, isDataLoading] = React.useState(true);

    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleClick = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = event.target;
        setAnchorEls(aels);
        //  setAnchorEl(event.currentTarget);
    };

    const handleClose = (id, event) => {
        // setAnchorEl(null);
        let aels = anchorEls.slice();
        aels[id] = null;
        setAnchorEls(aels);
    };

    /* Fetch all icons data */

    const [icons, setIcons] = React.useState([]);

    useEffect(()=> {
        getAllIcons().then(({data})=> {
            //console.log(data);
            let res = [];
            data.forEach((element)=>{
                let filename = "";
                if (element.image[0] !== undefined) {
                    filename = element.image[0].filename;
                } else {
                    filename = "no-image"
                }
                res.push({element, iconImage: filename})
            })
            setIcons(res);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=>{
            console.log(e);
        })
    }, []);

    /* Fetch all categories data */

    useEffect(()=> {
        getAllCategories().then(({data})=> {
            let res = [];
            data.RESPONSEDATA.forEach((category)=>{
                //console.log(category);
                let filename = "no-image";
                if (category.icon[0] !== undefined) {
                    filename = category.icon[0].filename;
                }
                res.push({id: category.id, category: category.title_fr, categoryIcon: url + filename, numOrder: category.num_order, showAction: ''});
                //console.log(res);
            })
            setCategories(res);
            // console.log(categories);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
            isDataLoading(false);
        })
    },[]);

    /* Delete Categorie */

    const [isOpen, setIsOpen] = useState(false);
    const [deletionDialogContent, setDeletionDialogContent] = useState("");
    const [submitFunction, setSubmitFunction] = useState(null);

    const handleOpenDeleteCategory = (data) => {
        console.log(data);
        const categoryDel = {
            "category_id": data.id
        }
        setSubmitFunction(() => ()=>{
            deleteCategory(categoryDel).then((data)=> {
                if (data.data.RESPONSECODE == 200) {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                    refreshPage();
                } else {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                }
            }).catch((e)=>{
                dispatch(setMessage({message : "Erreur lors de la suppression"}));
                dispatch(setOpen({open : true}));
                console.error(e);
            }).finally((e)=> {
                console.log(e);
            });
            handleCloseDeleteCategory();
        });
        setDeletionDialogContent("Êtes vous sûr de vouloir supprimer la catégorie " + data.category)
        setIsOpen(true);
    };

    const handleCloseDeleteCategory = () => {
        setIsOpen(false);
    };

    /* Add new category Form submit */

    const formik = useFormik({
        initialValues: {
            icon_id: undefined,
            num_order: undefined,
            title_fr: undefined,
            published: 1
        },
        validationSchema: Yup.object({
            icon_id: Yup.string()
                .required('Selectionner une icone'),
            num_order: Yup.string()
                .required('Ce champ requis'),
            title_fr: Yup.string()
                .required('Ce champ requis')
        }),
        onSubmit: (values, actions) => {
            let category = {...values};
            //console.log(category);
            createCategory(category).then(({data})=>{
                dispatch(setMessage({message : "Catégorie créee avec succès"}));
                dispatch(setOpen({open : true}));
                formik.resetForm();
                //console.log(data);
            }).catch((e)=>{
                dispatch(setMessage({message : "Erreur lors de la création"}));
                dispatch(setOpen({open : true}));
                console.error(e);
            }).finally((e)=>{
                console.log(e);
                actions.setSubmitting(false);
                handleCloseAddCategory();
                refreshPage();
            })
        },
    });

    /* Add category modal */

    const [addCategory, setAddCategory] = React.useState(false);

    const handleOpenAddCategory = () => {
        setAddCategory(true);
    };

    const handleCloseAddCategory = () => {
        setAddCategory(false);
    };

    /* Data Grid header infos */

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'category', headerName: 'Catégorie', width: 400 },
        { field: 'categoryIcon', headerName: 'Icone', width: 100,
            renderCell: (params) =>(
                <div>
                    <img style={{width: 30}} src={params.rowModel.data.categoryIcon} alt="icon"/>
                </div>
            ),
        },
        { field: 'numOrder', headerName: 'Ordre', width: 70,},
        { field: 'showAction', headerName: 'Actions', width: 100,
            renderCell: (params) => (
                <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={e => handleClick(params.rowModel.id, e)}>
                        Actions
                    </Button>
                    <Menu
                        id={params.rowModel.id}
                        anchorEl={anchorEls[params.rowModel.id]}
                        keepMounted
                        open={Boolean(anchorEls[params.rowModel.id])}
                        onClose={e => handleClose(params.rowModel.id, e)}
                    >
                        <Link href={`/dashboard/categories/${params.rowModel.data.id}/sous-categories`} onClick={e => handleClose(params.rowModel.id, e)}><VisibilityIcon/>Voir les sous catégories</Link>
                        <MenuItem onClick={e => handleClose(params.rowModel.id, e)}><CreateIcon/>Modifier</MenuItem>
                        <MenuItem onClick={(e) => {
                            handleClose(params.rowModel.id, e);
                            handleOpenDeleteCategory(params.rowModel.data);
                        }}>
                            <DeleteIcon/>Supprimer
                        </MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                { dataLoading ? <div className="data-loading"> <CircularProgress /> Chargement en cours... </div> : <div>Pas de données</div> }
            </GridOverlay>
        );
    }

    return (
        <div className="categories">
            <div className="page-header">
                <div className="page-header-add">
                    <Button onClick={handleOpenAddCategory}>Ajouter une catégorie</Button>
                    <Dialog
                        className="dialog large"
                        open={addCategory}
                        onClose={handleCloseAddCategory}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <IconButton aria-label="close" className="closeButton" onClick={handleCloseAddCategory}>
                            <MdClose />
                        </IconButton>
                        <div className="change-container custom-modal">
                            <DialogTitle id="dialog-title">{"Ajouter une catégorie"}</DialogTitle>
                            <DialogContent>
                                <div className="dialog-container">
                                    <form action="" onSubmit={formik.handleSubmit}>
                                        <div className="full-form-wrapper">
                                            <TextField
                                                name="title_fr"
                                                label="Nom de la catégorie"
                                                type="text"
                                                variant="outlined"
                                                className={"name-field" + (formik.errors.title_fr && formik.touched.title_fr ? ' is-invalid' : '')}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.title_fr && formik.errors.title_fr ? (
                                                <p className="errorMessage">{formik.errors.title_fr}</p>
                                            ) : null}
                                        </div>
                                        <div className="full-form-wrapper">
                                            <TextField
                                                name="num_order"
                                                label="Ajouter le numéro d'ordre"
                                                type="number"
                                                variant="outlined"
                                                className={"num-field" + (formik.errors.num_order && formik.touched.num_order ? ' is-invalid' : '')}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.num_order && formik.errors.num_order ? (
                                                <p className="errorMessage">{formik.errors.num_order}</p>
                                            ) : null}
                                        </div>
                                        <div className="full-form-wrapper">
                                            <FormControl
                                                className={"select-currency" + (formik.errors.icon_id && formik.touched.icon_id ? ' is-invalid' : '')}
                                            >
                                                <InputLabel id="category-label">Selectionner une icone</InputLabel>
                                                <Select
                                                    name="icon_id"
                                                    className="icon-selection"
                                                    id="currency"
                                                    labelId="category-label"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                >
                                                    {
                                                        icons.map((icon) => (
                                                                <MenuItem key={icon.element.id} value={icon.element.id}>
                                                                    <img className="category-icon" src={url + icon.iconImage} alt={icon.element.name}/>
                                                                    {icon.element.name}
                                                                </MenuItem>
                                                            )
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                            {formik.touched.icon_id && formik.errors.icon_id ? (
                                                <p className="errorMessage">{formik.errors.icon_id}</p>
                                            ) : null}
                                        </div>
                                        <div className="full-form-wrapper">
                                            <FormControlLabel
                                                value="1"
                                                control={<Checkbox />}
                                                label="Publier"
                                                labelPlacement="end"
                                                name="published"
                                                onChange={handleCheckboxChange}
                                                checked={checked}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </DialogContent>
                            <DialogActions className="confirmation-button">
                                <Button onClick={handleCloseAddCategory} color="primary">
                                    Annuler
                                </Button>
                                <Button type="submit" onClick={formik.handleSubmit} disabled={formik.isSubmitting} color="primary">
                                    Ajouter
                                    {
                                        formik.isSubmitting ?
                                            (<CircularProgress />) : ('')
                                    }
                                </Button>
                            </DialogActions>
                        </div>
                    </Dialog>
                </div>
                <div className="page-header-search">
                    <TextField
                        /*label="Recherche"*/
                        placeholder="Recherche"
                        type="text"
                        variant="outlined"
                        className="search-field"
                        name="search"
                        id="search"
                        InputProps={{
                            startAdornment: <SearchIcon/>
                        }}
                    />
                    <Button>Chercher</Button>
                </div>
            </div>
            <div className="categories-list">
                <div className="grid-wrapper" style={{ display: 'block', width: '100%' }}>
                    <DataGrid
                        rows={categories}
                        columns={columns}
                        pageSize={10}
                        autoHeight={true}
                        disableSelectionOnClick={true}
                        components={{
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                    />
                </div>
            </div>
            <DeleteDialog message={deletionDialogContent} isOpen={isOpen} submit={submitFunction} handleClose={handleCloseDeleteCategory} />
        </div>
    );
}