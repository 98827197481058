import React from "react";
import './AdminDashboard.scss';
import AdminHeader from "../AdminHeader/AdminHeader";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { Icon } from '@iconify/react';
import userAvatarFilledAlt from '@iconify/icons-carbon/user-avatar-filled-alt';
import bookmarkMultiple from '@iconify/icons-mdi/bookmark-multiple';
import toggleOff from '@iconify/icons-gg/toggle-off';
import envelopeOpenText from '@iconify/icons-fa-solid/envelope-open-text';
import roundSupportAgent from '@iconify/icons-ic/round-support-agent';
import basketIcon from '@iconify/icons-mdi/basket';
import bubbleMessageDot2 from '@iconify/icons-si-glyph/bubble-message-dot-2';
import LanguageIcon from '@material-ui/icons/Language';
import DialpadIcon from '@material-ui/icons/Dialpad';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import ColorLensIcon from '@material-ui/icons/ColorLens';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';

import AdminMembers from "./Members/AdminMembers";
import AdminMembersDetails from "./Members/Details/adminMembersDetails";
import AdminAdverts from "./Adverts/AdminAdverts";
import AdminOptions from "./Options/AdminOptions";
import AdminSupportsTickets from "./SupportsTickets/AdminSupportsTickets";
import AdminOrders from "./Orders/AdminOrders";
import AdminDiscussions from "./Discussions/AdminDiscussions";
import AdminZones from "./Zones/AdminZones";
import AdminCountries from "./Countries/AdminCountries";
import AdminCities from "./Cities/AdminCities";
import AdminCategories from "./Categories/AdminCategories";
import AdminSubCategories from "./SubCategories/AdminSubCategories";
import AdminBrands from "./Brands/AdminBrands";
import AdminIcons from "./Parameters/Icons/AdminIcons";
import AdminNewsletters from "./Newletters/AdminNewsletters";
import AdminClearCache  from "./ClearCache/AdminClearCache";
import AdminModels from "./Models/AdminModels";
import CreateAdmin from "../CreateAdmin/CreateAdmin";
import AdminDiscussionsDetails from "./Discussions/Details/AdminDiscussionsDetails";
import AdminAdvertDetails from "./Adverts/Details/AdminAdvertDetails";
import {useSelector} from "react-redux";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Accordion from "@material-ui/core/Accordion";
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import LaptopIcon from '@material-ui/icons/Laptop';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import BookIcon from '@material-ui/icons/Book';
import AdminColors from "./Parameters/Colors/AdminColors";

export default function AdminDashboard() {

    const currentUser = useSelector(state => state.user.currentUser);

    return (
        <div>
            <AdminHeader />
            <div className="admin-dashboard-content">
                <div className="admin-dashboard-content-sidebar">
                    <div className="admin-dashboard-content-sidebar-menu">
                        <ul>
                            <li>
                                <NavLink to={`/dashboard/members`}>
                                    <Icon icon={userAvatarFilledAlt} />
                                    <span>Members</span>
                                </NavLink>
                            </li>
                            <li className="collapse">
                                <NavLink to={`/dashboard/adverts`}>
                                    <Icon icon={bookmarkMultiple} />
                                    <span>Annonces</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/dashboard/options`}>
                                    <Icon icon={toggleOff} />
                                    <span>Options</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/dashboard/supports-tickets`}>
                                    <Icon icon={roundSupportAgent} />
                                    <span>Supports et tickets</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/dashboard/orders`}>
                                    <Icon icon={basketIcon} />
                                    <span>Commandes</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/dashboard/discussions`}>
                                    <Icon icon={bubbleMessageDot2} />
                                    <span>Discussions</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/dashboard/countries`}>
                                    <LanguageIcon className="customIcon"/>
                                    <span>Pays</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/dashboard/categories`}>
                                    <DialpadIcon className="customIcon"/>
                                    <span>Catégories</span>
                                </NavLink>
                            </li>
                             <li className="collapse">
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        className="collapse-header">
                                        <Typography>
                                           <BookIcon className="customIcon"/>
                                            <span>Marques</span>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <NavLink to={`/dashboard/brands/1/voitures`}>
                                            <DriveEtaIcon className="customIcon"/>
                                            <span>Voitures</span>
                                        </NavLink>
                                        <NavLink to={`/dashboard/brands/3/motos`}>
                                            <MotorcycleIcon className="customIcon"/>
                                            <span>Motos</span>
                                        </NavLink>
                                        <NavLink to={`/dashboard/brands/5/camions`}>
                                            <LocalShippingIcon className="customIcon"/>
                                            <span>Camions</span>
                                        </NavLink>
                                        <NavLink to={`/dashboard/brands/2/telephones`}>
                                            <PhoneAndroidIcon className="customIcon"/>
                                            <span>Téléphones</span>
                                        </NavLink>
                                        <NavLink to={`/dashboard/brands/4/ordinateurs`}>
                                            <LaptopIcon className="customIcon"/>
                                            <span>Ordinateurs</span>
                                        </NavLink>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            <li className="collapse">
                                <Accordion>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                        className="collapse-header">
                                        <Typography>
                                            <SettingsIcon className="customIcon"/>
                                            <span>Paramètres</span>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <NavLink to={`/dashboard/parameters/icons`}>
                                            <ArtTrackIcon className="customIcon"/>
                                            <span>Icones</span>
                                        </NavLink>
                                        <NavLink to={`/dashboard/parameters/colors`}>
                                            <ColorLensIcon className="customIcon"/>
                                            <span>Couleurs</span>
                                        </NavLink>
                                    </AccordionDetails>
                                </Accordion>
                            </li>
                            {/*<li>
                                <NavLink to={`/dashboard/parameters`}>
                                    <SettingsIcon className="customIcon"/>
                                    <span>Paramètres</span>
                                </NavLink>
                            </li>*/}
                            <li>
                                <NavLink to={`/dashboard/newsletters`}>
                                    <Icon icon={envelopeOpenText} className="customIcon"/>
                                    <span>Newsletters</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={`/dashboard/clear-cache`}>
                                    <DeleteIcon className="customIcon"/>
                                    <span>Vider le cache</span>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="admin-dashboard-content-body">
                    <Switch>
                        <Route exact path="/dashboard/countries" component={AdminCountries} />
                        <Route path="/dashboard/countries/:id/cities" component={AdminCities} />
                        <Route path="/dashboard/countries/cities/:id/zones" component={AdminZones} />
                        <Route exact path="/dashboard/categories" component={AdminCategories} />
                        <Route path="/dashboard/categories/:id/sous-categories" component={AdminSubCategories} />
                        <Route exact path="/dashboard/brands/:type/:brandName" component={AdminBrands} />
                        <Route path="/dashboard/brands/:type/:id/:brandName/models" component={AdminModels} />
                        <Route exact path="/dashboard/members" component={AdminMembers} />
                        <Route path="/dashboard/members/:id/utilisateur" component={AdminMembersDetails} />
                        <Route exact path="/dashboard/adverts" component={AdminAdverts} />
                        <Route path="/dashboard/adverts/:id/ad" component={AdminAdvertDetails} />
                        <Route path="/dashboard/options" component={AdminOptions} />
                        <Route path="/dashboard/supports-tickets" component={AdminSupportsTickets} />
                        <Route path="/dashboard/orders" component={AdminOrders} />
                        <Route exact path="/dashboard/discussions" component={AdminDiscussions} />
                        <Route path="/dashboard/discussions/:id/message" component={AdminDiscussionsDetails} />
                        <Route path="/dashboard/parameters/colors" component={AdminColors} />
                        <Route path="/dashboard/parameters/icons" component={AdminIcons} />
                        <Route path="/dashboard/newsletters" component={AdminNewsletters} />
                        <Route path="/dashboard/clear-cache" component={AdminClearCache} />
                        {
                            currentUser.type == 7 ? (<Route exact path="/dashboard/create-admin" component={CreateAdmin} />) : ("")
                        }

                        {/* Si la route n'existe pas ou n'est pas référencée */}
                        <Route>
                            <Redirect to="/not-found" />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
}