import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useFormik} from "formik";
import * as Yup from "yup";
import {setMessage, setOpen} from "../../../../../../reducers/snackbarReducer";
import {createColor, updateColor} from "../../../../../../services/color";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, TextField} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function ColorDialogComponent ({showDialog, color, handleCloseColor}) {
    /* Refresher function */

    function refreshPage() {
        window.location.reload();
    }

    //console.log(color);

    const dispatch = useDispatch();

    /* Add or update a new color */

    let formik = useFormik({
        initialValues: {
            name: undefined,
            code: undefined,
            /*num_order: undefined,*/
            published: color.published
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required('Ce champ est requis'),
            code: Yup.string()
                .required('Ce champ est requis'),
            /*num_order: Yup.string()
                .required('Ce champ est requis')*/
        }),

        onSubmit: (values, actions) => {
            let colorInfos = {...values};
            colorInfos.published = colorInfos.published == false ? 0 : 1;
            if (color.id) {
                colorInfos.id = color.id;
                //console.log(colorInfos);
                updateColor(colorInfos).then(({data})=> {
                    dispatch(setMessage({message : "Couleur mis à jour avec succès"}));
                    dispatch(setOpen({open : true}));
                    formik.resetForm();
                    refreshPage();
                }).catch((e)=>{
                    dispatch(setMessage({message : "Erreur lors de la création"}));
                    dispatch(setOpen({open : true}));
                    console.error(e);
                }).finally((e)=>{
                    console.log(e);
                    actions.setSubmitting(false);
                    refreshPage();
                })
            } else {
                //console.log(colorInfos);
                createColor(colorInfos).then(({data})=> {
                    dispatch(setMessage({message : "Couleur crée avec succès"}));
                    dispatch(setOpen({open : true}));
                    formik.resetForm();
                    refreshPage();
                }).catch((e)=>{
                    dispatch(setMessage({message : "Erreur lors de la création"}));
                    dispatch(setOpen({open : true}));
                    console.error(e);
                }).finally((e)=> {
                    console.log(e);
                    actions.setSubmitting(false);
                    //refreshPage();
                })
            }
        }
    });

    useEffect(()=>{
        if(color.id){
            formik.values.name = color.colorName;
            /*formik.values.num_order = color.numOrder;*/
            formik.values.code = color.colorCode
        }else {
            formik.values.name = undefined;
            /*formik.values.num_order = undefined;*/
            formik.values.code = undefined;
        }
    }, [color]);

    return (
        <div className="color-dialog">
            <Dialog
                className="dialog large"
                open={showDialog}
                onClose={handleCloseColor}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <IconButton aria-label="close" className="closeButton" onClick={handleCloseColor}>
                    <MdClose />
                </IconButton>
                <div className="change-container custom-modal">
                    <DialogTitle id="dialog-title">{color.id? "Modification d'une couleur" : "Création d'une couleur" }</DialogTitle>
                    <DialogContent>
                        <div className="dialog-container">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="full-form-wrapper">
                                    <TextField
                                        name="name"
                                        label="Nom de la couleur"
                                        type="text"
                                        variant="outlined"
                                        className={"name-field" + (formik.errors.name && formik.touched.name ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={color.colorName}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <p className="errorMessage">{formik.errors.name}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper">
                                    <TextField
                                        name="code"
                                        label="Ajouter le code couleur"
                                        type="text"
                                        variant="outlined"
                                        className={"num-field" + (formik.errors.code && formik.touched.code ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={color.colorCode}
                                    />
                                    {formik.touched.code && formik.errors.code ? (
                                        <p className="errorMessage">{formik.errors.code}</p>
                                    ) : null}
                                </div>
                                {/*<div className="full-form-wrapper">
                                    <TextField
                                        name="num_order"
                                        label="Ajouter le numéro d'ordre"
                                        type="number"
                                        variant="outlined"
                                        className={"num-field" + (formik.errors.num_order && formik.touched.num_order ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={color.numOrder}
                                    />
                                    {formik.touched.num_order && formik.errors.num_order ? (
                                        <p className="errorMessage">{formik.errors.num_order}</p>
                                    ) : null}
                                </div>*/}
                                <div className="full-form-wrapper published">
                                    <FormControlLabel
                                        control={
                                            <div>
                                                <Checkbox
                                                    name="published"
                                                    color="secondary"
                                                    id="published"
                                                    defaultChecked={true}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.published}
                                                />
                                            </div>
                                        }
                                        label={<label htmlFor="published">Publier</label>} />
                                </div>
                            </form>
                        </div>
                    </DialogContent>
                    <DialogActions className="confirmation-button">
                        <Button onClick={() => {
                            formik.resetForm()
                            handleCloseColor();
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button type="submit" onClick={formik.handleSubmit} disabled={formik.isSubmitting} color="primary">
                            {color.id ? "Modifier" : "Créer" }
                            {
                                formik.isSubmitting ?
                                    (<CircularProgress />) : ('')
                            }
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}