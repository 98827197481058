import React, {useEffect, useState} from "react";
import './createAdmin.scss';
import AdminHeader from "../AdminHeader/AdminHeader";
import {Button, TextField} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import {DeleteDialog} from "../Dashboard/DeleteDialog/DeleteDialog";
import {DataGrid, GridOverlay} from "@material-ui/data-grid";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import RemoveCircleOutlinedIcon from '@material-ui/icons/RemoveCircleOutlined';
import {deleteAdminUser, getAllAdminUsers} from "../../../services/admin";
import {setMessage, setOpen} from "../../../reducers/snackbarReducer";
import {useDispatch} from "react-redux";
import AdminDialogComponent from "./adminDialogComponent/adminDialogComponent";

export default function CreateAdmin() {

    /* Refresher function */

    function refreshPage() {
        window.location.reload();
    }

    const dispatch = useDispatch();

    function checkType(adminTypeNumber) {
        //console.log(adminTypeNumber);
        switch (adminTypeNumber) {
            case '1':
                return ("Agent")
            case '2':
                return ("Super Agent")
            case '3':
                return ("Auditeur Général")
            case '4':
                return ("Auditeur Pays")
            case '5':
                return ("Administrateur Général")
            case '6':
                return ("Administrateur Pays")
            case '7':
                return ("Super Administrateur")
            default:
                return ("Administrateur non trouvé.")
        }
    }

    /* Country Each Menu view */

    const [anchorEls, setAnchorEls] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [dataLoading, isDataLoading] = useState(true);
    const [showAdminDialog, setShowAdminDialog] = useState(false);
    const [rowData, setRowData] = useState({});

    const handleClick = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = event.target;
        setAnchorEls(aels);
        //  setAnchorEl(event.currentTarget);
    };

    const handleClose = (id, event) => {
        // setAnchorEl(null);
        let aels = anchorEls.slice();
        aels[id] = null;
        setAnchorEls(aels);
    };

    /* Update and add dialog */

    const openAdminDialog = (data) => {
        setRowData(data);
        setShowAdminDialog(true);
    }

    const closeAdminDialog = () => {
        setRowData({});
        setShowAdminDialog(false);
    }

    /* Suspended modal */

    const [suspendedAdmin, setSuspendedAdmin] = React.useState(false);
    const [rowDataSuspended, setRowDataSuspended] = React.useState(undefined);

    const handleOpenSuspendedAdmin = (data) => {
        //console.log(data);
        setRowDataSuspended(data);
        setSuspendedAdmin(true);
    };

    const handleCloseSuspendedAdmin = () => {
        setSuspendedAdmin(false);
    };

    /* Open the delete dialog */

    const [isOpen, setIsOpen] = useState(false);
    const [deletionDialogContent, setDeletionDialogContent] = useState("");
    const [submitFunction, setSubmitFunction] = useState(null);

    const handleClickOpenDeleteAdminDialog = (data) => {
        const userDel = {
            "user_id": data.id
        }
        setSubmitFunction(() => ()=>{
            deleteAdminUser(userDel).then((data)=> {
                if (data.data.RESPONSECODE == 200) {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                    refreshPage();
                } else {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                }
            }).catch((e)=>{
                dispatch(setMessage({message : "Erreur lors de la suppression"}));
                dispatch(setOpen({open : true}));
                console.error(e);
            }).finally((e)=> {
                console.log(e);
            });
            handleClickCloseDeleteAdminDialog();
        });
        setDeletionDialogContent("Êtes vous sûr de vouloir supprimer cet administrateur " + data.username + " ?")
        setIsOpen(true);
    };

    const handleClickCloseDeleteAdminDialog = (id, event) => {
        setIsOpen(false);
    };

    /* Get all Admins */

    useEffect(()=>{
        getAllAdminUsers().then(({data}) => {
            let adminsData = data.RESPONSEDATA;
            //console.log(adminsData);
            let res = [];
            adminsData.forEach((admin)=> {
                res.push({
                    id: admin.id,
                    username: admin.username,
                    lastName: admin.first_name,
                    firstName: admin.last_name,
                    email: admin.email,
                    countries : admin.countries,
                    countriesId :
                        admin.countries.map((country) => (
                            country.id
                        )),
                    country:
                        admin.countries.map((country) => (
                            " "+ country.name
                        )),
                    phone: "+" + admin.phone_indicatif + admin.phone,
                    fullphone: admin.phone_indicatif + admin.phone,
                    indicatif: admin.phone_indicatif,
                    role: checkType(admin.type),
                    status: ((admin.suspended == null) || (admin.suspended == 0)  ? 'Actif' : 'Suspendu'),
                    userType: admin.type,
                    password: admin.password,
                    showAction: ''
                });
            })
            setAdmins(res);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
            isDataLoading(false);
        })
    },[]);

    /* Data Grid header infos */

    const columns = [
        /*{ field: 'id', headerName: 'ID', width: 70 },*/
        { field: 'username', headerName: 'Identifiant', width: 130},
        { field: 'lastName', headerName: 'Nom', width: 130},
        { field: 'firstName', headerName: 'Prénom', width: 130},
        { field: 'email', headerName: 'Email', width: 130},
        { field: 'phone', headerName: 'Téléphone', width: 130},
        { field: 'role', headerName: 'Rôle', width: 200},
        { field: 'country', headerName: 'Pays', width: 200},
        { field: 'status', headerName: 'Status', width: 130},
        { field: 'showAction', headerName: 'Actions', width: 100,
            renderCell: (params) => (
                <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={e => handleClick(params.rowModel.id, e)}>
                        Actions
                    </Button>
                    <Menu
                        id={params.rowModel.id}
                        anchorEl={anchorEls[params.rowModel.id]}
                        keepMounted
                        open={Boolean(anchorEls[params.rowModel.id])}
                        onClose={e => handleClose(params.rowModel.id, e)}
                    >
                        <MenuItem onClick={(e) => {
                            handleClose(params.rowModel.id, e);
                            openAdminDialog(params.rowModel.data);
                        }}>
                            <CreateIcon/>Modifier
                        </MenuItem>
                        {/*<MenuItem onClick={(e) => {
                            handleClose(params.rowModel.id, e);
                            handleOpenSuspendedAdmin(params.rowModel.data);
                        }}>
                            <RemoveCircleOutlinedIcon/>Suspendre
                        </MenuItem>*/}
                        <MenuItem onClick={(e) => {
                            handleClose(params.rowModel.id, e);
                            handleClickOpenDeleteAdminDialog(params.rowModel.data);
                        }}>
                            <DeleteIcon/>Supprimer
                        </MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    /* No data display */

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                { dataLoading ? <div className="data-loading"> <CircularProgress /> Chargement en cours... </div> : <div>Pas de données</div> }
            </GridOverlay>
        );
    };

    return (
        <div>
            <AdminHeader />
            <div className="create-admin-content">
                <div className="page-header">
                    <div className="page-header-add">
                        {/* Add admin button */}
                        <Button onClick={openAdminDialog}>Ajouter un admin</Button>
                        <AdminDialogComponent showDialog={showAdminDialog} admin={rowData} handleClose={closeAdminDialog} />
                    </div>
                    {/* Admin search form */}
                    <div className="page-header-search">
                        <TextField
                            /*label="Recherche"*/
                            placeholder="Recherche"
                            type="text"
                            variant="outlined"
                            className="search-field"
                            name="search"
                            id="search"
                            InputProps={{
                                startAdornment: <SearchIcon/>
                            }}
                        />
                        <Button>Chercher</Button>
                    </div>
                </div>
                {/* Admin data grid */}
                <div className="admin-list">
                    <div style={{ display: 'block', height: '400px', width: '100%' }}>
                        <DataGrid
                            rows={admins}
                            columns={columns}
                            pageSize={10}
                            autoHeight={true}
                            disableSelectionOnClick={true}
                            components={{
                                noRowsOverlay: CustomNoRowsOverlay,
                            }}
                        />
                    </div>
                </div>
            </div>
            <DeleteDialog message={deletionDialogContent} isOpen={isOpen} submit={submitFunction} handleClose={handleClickCloseDeleteAdminDialog} />
            {/*Suspended dialog*/}
            <Dialog
                className="dialog"
                open={suspendedAdmin}
                onClose={handleCloseSuspendedAdmin}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <IconButton aria-label="close" className="closeButton" onClick={handleCloseSuspendedAdmin}>
                    <MdClose />
                </IconButton>
                <div className="change-container custom-modal">
                    <DialogTitle id="dialog-title">{"Suspendre un admin"}</DialogTitle>
                    <DialogContent>
                        <div className="dialog-container">
                            <p>Etes vous sur de vouloir suspendre l'utilisateur {rowDataSuspended ? rowDataSuspended.username : ''} ?</p>
                        </div>
                    </DialogContent>
                    <DialogActions className="confirmation-button">
                        <Button onClick={handleCloseSuspendedAdmin} color="primary">
                            Annuler
                        </Button>
                        <Button type="submit" color="primary">
                            Suspendre
                            {/*{
                                    (<CircularProgress />) : ('')
                            }*/}
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}