import axios from 'axios';
import {
  apiUrl,
} from '../utils/axios';

export function getAllCountries(){
    return axios.get(`${apiUrl}/admin_get_countries`);
}

export function getCountryWithCities(id_country) {
    return axios.get(`${apiUrl}/admin_get_country_cities/${id_country}`);
}

/* Create */

export function createCountry(dataCreate) {
    return axios.post(`${apiUrl}/admin_country_registration`, dataCreate);
}

/* Delete */

export function deleteCountry(dataDelete) {
    return axios.post(`${apiUrl}/admin_deletion_country`, dataDelete);
}

/* Update */

export function updateCountry(dataUpdate) {
    return axios.post(`${apiUrl}/admin_country_update`, dataUpdate);
}