import React, {useEffect} from "react";
import IconButton from "@material-ui/core/IconButton";
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, TextField} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import {useFormik} from "formik";
import * as Yup from "yup";
import {setMessage, setOpen} from "../../../../../reducers/snackbarReducer";
import {useDispatch} from "react-redux";
import {createSubCategory, updateSubCategory} from "../../../../../services/subCategory";

export default function SubCategoryDialogComponent ({showDialog, subcategory, categoryId, handleClose}) {

    /* Refresher function */

    function refreshPage() {
        window.location.reload();
    }

    const dispatch = useDispatch();

    /* Add or update a new city */

    const formik = useFormik({
        initialValues: {
            parent_id: categoryId,
            category_id: subcategory.id_original,
            num_order: undefined,
            title_fr: undefined,
            published: 1
        },
        validationSchema: Yup.object({
            num_order: Yup.string()
                .required('Ce champ est requis'),
            title_fr: Yup.string()
                .required('Ce champ est requis'),
        }),
        onSubmit: (values, actions) => {
            let subCategoryInfos = {...values};
            subCategoryInfos.published = subCategoryInfos.published == false ? 0 : 1;
            //console.log(subCategory)
            if (subcategory.id_original) {
                //console.log(subCategoryInfos);
                updateSubCategory(subCategoryInfos).then(({})=>{
                    dispatch(setMessage({message : "Sous catégorie créee avec succès"}));
                    dispatch(setOpen({open : true}));
                    formik.resetForm();
                    //console.log(data);
                }).catch((e)=>{
                    dispatch(setMessage({message : "Erreur lors de la modification"}));
                    dispatch(setOpen({open : true}));
                    console.error(e);
                }).finally((e)=>{
                    console.log(e);
                    actions.setSubmitting(false);
                    refreshPage();
                })
            } else {
                delete subCategoryInfos.category_id;
                //console.log(subCategoryInfos);
                createSubCategory(subCategoryInfos).then(({data})=>{
                    dispatch(setMessage({message : "Sous catégorie créee avec succès"}));
                    dispatch(setOpen({open : true}));
                    formik.resetForm();
                    //console.log(data);
                }).catch((e)=>{
                    dispatch(setMessage({message : "Erreur lors de la création"}));
                    dispatch(setOpen({open : true}));
                    console.error(e);
                }).finally((e)=>{
                    console.log(e);
                    actions.setSubmitting(false);
                    refreshPage();
                })
            }
        },
    });

    useEffect(()=>{
        if(subcategory.id_original){
            formik.values.title_fr = subcategory.subCategory;
            formik.values.num_order = subcategory.numOrder;
            formik.values.parent_id = categoryId;
            formik.values.category_id = subcategory.id_original;
        }else {
            formik.values.title_fr = undefined;
            formik.values.num_order = undefined;
            formik.values.parent_id = categoryId;
            formik.values.category_id = subcategory.id_original;
        }
    }, [subcategory]);

    return (
        <div className="city-dialog">
            <Dialog
                className="dialog large"
                open={showDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <IconButton aria-label="close" className="closeButton" onClick={handleClose}>
                    <MdClose />
                </IconButton>
                <div className="change-container custom-modal">
                    <DialogTitle id="dialog-title">{ subcategory.id ? "Modification d'une sous catégorie" : "Création d'une sous catégorie" }</DialogTitle>
                    <DialogContent>
                        <div className="dialog-container">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="full-form-wrapper">
                                    <TextField
                                        name="title_fr"
                                        label="Nom de la sous catégorie"
                                        type="text"
                                        variant="outlined"
                                        className={"name-field" + (formik.errors.title_fr && formik.touched.title_fr ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={subcategory.subCategory}
                                    />
                                    {formik.touched.title_fr && formik.errors.title_fr ? (
                                        <p className="errorMessage">{formik.errors.title_fr}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper">
                                    <TextField
                                        name="num_order"
                                        label="Ajouter le numéro d'ordre"
                                        type="number"
                                        variant="outlined"
                                        className={"num-field" + (formik.errors.num_order && formik.touched.num_order ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={subcategory.numOrder}
                                    />
                                    {formik.touched.num_order && formik.errors.num_order ? (
                                        <p className="errorMessage">{formik.errors.num_order}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper published">
                                    <FormControlLabel
                                        control={
                                            <div>
                                                <Checkbox
                                                    name="published"
                                                    color="secondary"
                                                    id="published"
                                                    defaultChecked={true}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.published}
                                                />
                                            </div>
                                        }
                                        label={<label htmlFor="published">Publier</label>} />
                                </div>
                            </form>
                        </div>
                    </DialogContent>
                    <DialogActions className="confirmation-button">
                        <Button onClick={() => {
                            formik.resetForm()
                            handleClose();
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button type="submit" onClick={formik.handleSubmit} disabled={formik.isSubmitting} color="primary">
                            {subcategory.id ? "Modifier" : "Créer" }
                            {
                                formik.isSubmitting ?
                                    (<CircularProgress />) : ('')
                            }
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}