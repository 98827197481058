import React, {useEffect, useState} from "react";
import './AdminCountries.scss';
import {Button, TextField} from "@material-ui/core";
import {GridOverlay, DataGrid} from '@material-ui/data-grid';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Link from "@material-ui/core/Link";
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {createCountry, getAllCountries, deleteCountry, updateCountry} from "../../../../services/country";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {MdClose} from "react-icons/md";
import IconButton from "@material-ui/core/IconButton";
import {getAllCurrencies} from "../../../../services/currency";
import {useFormik} from "formik";
import * as Yup from "yup";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Icon from "@iconify/react";
import cameraPlusOutline from "@iconify/icons-mdi/camera-plus-outline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { DeleteDialog } from "../DeleteDialog/DeleteDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useDispatch} from "react-redux";
import {setMessage, setOpen} from "../../../../reducers/snackbarReducer";
import {staticApiUrl} from '../../../../utils/axios';

export default function AdminCountries() {

    /* Refresher function */

    function refreshPage() {
        window.location.reload();
    }

    const dispatch = useDispatch();

    /* Open the delete dialog */

    const [isOpen, setIsOpen] = useState(false);
    const [deletionDialogContent, setDeletionDialogContent] = useState("");
    const [submitFunction, setSubmitFunction] = useState(null);

    const handleClickOpenDeleteDialog = (data) => {
        const countryDel = {
            "country_id": data.id
        }
        setSubmitFunction(() => ()=>{
            deleteCountry(countryDel).then((data)=> {
                if (data.data.RESPONSECODE == 200) {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                    refreshPage();
                } else {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                }
            }).catch((e)=>{
                dispatch(setMessage({message : "Erreur lors de la suppression"}));
                dispatch(setOpen({open : true}));
                console.error(e);
            }).finally((e)=> {
                console.log(e);
            });
            handleClickCloseDeleteDialog();
        });
        setDeletionDialogContent("Êtes vous sûr de vouloir supprimer le pays " + data.country)
        setIsOpen(true);
    };

    const handleClickCloseDeleteDialog = (id, event) => {
        setIsOpen(false);
    };

    /* Country Each Menu view */

    const [anchorEls, setAnchorEls] = React.useState([]);
    const [countries, setCountries] = React.useState([]);
    const [dataLoading, isDataLoading] = React.useState(true);
    const [picture, setPicture] = React.useState(undefined);
    const [pictureFlag, setPictureFlag] = React.useState(undefined);
    const [updateCountry, setUpdateCountry] = React.useState(false);
    const [rowData, setRowData] = React.useState([]);
    const fileInput = React.createRef();
    const fileInputFlag = React.createRef();

    /*const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
    };*/

    /* Actions constantes declared */

    const handleClick = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = event.target;
        setAnchorEls(aels);
    };

    const handleClose = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = null;
        setAnchorEls(aels);
    };

    /* Get all currencies */

    const [currencies, setCurrencies] = React.useState([]);

    useEffect(()=>{
        getAllCurrencies().then(({data})=>{
            setCurrencies(data);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
        })
    }, []);

    /* Fetch all countries data */

    useEffect(()=>{
        getAllCountries().then(({data})=> {
            //console.log(data);
            let res = [];
            let url = `${staticApiUrl}/countryimages/`;
            data.forEach((country)=>{
                let filename = "no-image";
                if (country.images[0] !== undefined) {
                    filename = country.images[0].filename;
                }
                res.push({id: country.id, country: country.name, countryFlag : url + filename, currency : "FCFA", slug: country.slug, numOrder: country.num_order, showAction: ''});
            })
            setCountries(res);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
            isDataLoading(false);
        })
    },[]);

    /* Add country modal */

    const [addCountry, setAddCountry] = React.useState(false);

    const handleOpenAddCountry = () => {
        setAddCountry(true);
    };

    const handleCloseAddCountry = () => {
        setAddCountry(false);
    };

    /* Open update dialog */

    const handleOpenUpdateCountry = (data) => {
        console.log(data);
        setRowData(data);
        setPictureFlag(rowData.countryFlag);
        setUpdateCountry(true);
    };

    const handleCloseUpdateCountry = () => {
        setUpdateCountry(false);
    };

    /* File select script */

    const handleChange = (e) => {
        let file = (fileInput.current.files[0]);
        if(file){
            let reader = new FileReader();
            reader.onload = function(e) {
                updatePicture(e.target.result);
            };
            reader.readAsDataURL(file);
            formik.setFieldValue(e.target.name, file);
        }
    }

    const updatePicture = (url) => {
        setPicture(url);
    }

    const handleChangeFlag = (e) => {
        let file = (fileInputFlag.current.files[0]);
        if(file){
            let reader = new FileReader();
            reader.onload = function(e) {
                updatePictureFlag(e.target.result);
            };
            reader.readAsDataURL(file);
            formik.setFieldValue(e.target.name, file);
        }
    }

    const updatePictureFlag = (url) => {
        setPictureFlag(url);
    }

    const FILE_SIZE = 160 * 1024;
    const SUPPORTED_FORMATS = [
        "image/jpg",
        "image/jpeg",
        "image/gif",
        "image/png"
    ];

    /* Add new country Form submit */

    const formik = useFormik({
        initialValues: {
            currency_id: undefined,
            num_order: undefined,
            name: undefined,
            slug: undefined,
            file: undefined,
            published: true
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required('Ce champ est requis'),
            slug: Yup.string()
                .required('Ce champ est requis'),
            currency_id: Yup.string()
                .required('Ce champ est requis'),
            num_order: Yup.string()
                .required('Ce champ est requis'),
            file: Yup.mixed()
                .required('Ce champ est requis')
                .test(
                    "fileSize",
                    "Ce fichier est trop volumineux",
                    value => value && value.size <= FILE_SIZE
                )
                .test(
                    "fileFormat",
                    "Ce format n'est pas supporté",
                    value => value && SUPPORTED_FORMATS.includes(value.type)
                )
        }),
        onSubmit: (values, actions) => {
            let country = { ...values, images : [picture]};
            delete country.file;
            country.published = country.published == false ? 0 : 1;
            //console.log(JSON.stringify(country, null, 2));
            createCountry(country).then(({data})=>{
                dispatch(setMessage({message : "Pays crée avec succès"}));
                dispatch(setOpen({open : true}));
                formik.resetForm();
                //console.log(data);
            }).catch((e)=>{
                dispatch(setMessage({message : "Erreur lors de la création"}));
                dispatch(setOpen({open : true}));
                console.error(e);
            }).finally((e)=>{
                console.log(e);
                actions.setSubmitting(false);
                handleCloseAddCountry();
                refreshPage();
            })
        },
    });

    /* Data Grid header infos */

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'country', headerName: 'Pays', width: 200 },
        { field: 'countryFlag', headerName: 'Drapeau', width: 100,
            renderCell: (params) =>(
                <div>
                    <img style={{width: 30}} src={params.rowModel.data.countryFlag} alt="drapeau"/>
                </div>
            ),
        },
        { field: 'currency', headerName: 'Devise', width: 100,},
        { field: 'slug', headerName: 'Slug', width: 100,},
        { field: 'numOrder', headerName: 'Ordre', width: 70,},
        { field: 'showAction', headerName: 'Actions', width: 100,
            renderCell: (params) => (
                <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={e => handleClick(params.rowModel.id, e)}>
                        Actions
                    </Button>
                    <Menu
                        id={params.rowModel.id}
                        anchorEl={anchorEls[params.rowModel.id]}
                        keepMounted
                        open={Boolean(anchorEls[params.rowModel.id])}
                        onClose={e => handleClose(params.rowModel.id, e)}
                    >
                        <Link href={`/dashboard/countries/${params.rowModel.data.id}/cities`} onClick={e => handleClose(params.rowModel.id, e)}><VisibilityIcon/>Voir toutes les villes</Link>
                        <MenuItem onClick={e => {
                            handleClose(params.rowModel.id, e);
                            handleOpenUpdateCountry(params.rowModel.data, e);
                        }}><CreateIcon/>Modifier</MenuItem>
                        <MenuItem onClick={(e) => {
                            handleClose(params.rowModel.id, e);
                            handleClickOpenDeleteDialog(params.rowModel.data, e);
                        }}>
                            <DeleteIcon/>Supprimer
                        </MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    /* No data display */

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                { dataLoading ? <div className="data-loading"> <CircularProgress /> Chargement en cours... </div> : <div>Pas de données</div> }
            </GridOverlay>
        );
    };

    return (
        <div className="countries">
            <div className="page-header">
                <div className="page-header-add">
                    {/* Add country button */}
                    <Button onClick={handleOpenAddCountry}>Ajouter un pays</Button>
                    {/* Add country dialog */}
                    <Dialog
                        className="dialog large"
                        open={addCountry}
                        onClose={handleCloseAddCountry}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <IconButton aria-label="close" className="closeButton" onClick={handleCloseAddCountry}>
                            <MdClose />
                        </IconButton>
                        <div className="change-container custom-modal">
                            <DialogTitle id="dialog-title">{"Ajout d'un pays"}</DialogTitle>
                            <DialogContent>
                                <div className="dialog-container">
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="full-form-wrapper">
                                            <FormControl
                                                className={"select-currency" + (formik.errors.currency_id && formik.touched.currency_id ? ' is-invalid' : '')}
                                            >
                                                <InputLabel id="category-label">Selectionner une devise</InputLabel>
                                                <Select
                                                    name="currency_id"
                                                    id="currency"
                                                    labelId="category-label"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                >
                                                    {
                                                        currencies.map((currency) => (
                                                                <MenuItem key={currency.id} value={currency.id}>{currency.name}</MenuItem>
                                                            )
                                                        )
                                                    }
                                                </Select>
                                            </FormControl>
                                            {formik.touched.currency_id && formik.errors.currency_id ? (
                                                <p className="errorMessage">{formik.errors.currency_id}</p>
                                            ) : null}
                                        </div>
                                        <div className="full-form-wrapper">
                                            <TextField
                                                name="num_order"
                                                label="Ajouter le numéro d'ordre"
                                                type="number"
                                                variant="outlined"
                                                className={"num-field" + (formik.errors.num_order && formik.touched.num_order ? ' is-invalid' : '')}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.num_order && formik.errors.num_order ? (
                                                <p className="errorMessage">{formik.errors.num_order}</p>
                                            ) : null}
                                        </div>
                                        <div className="full-form-wrapper">
                                            <TextField
                                                name="name"
                                                label="Nom du pays"
                                                type="text"
                                                variant="outlined"
                                                className={"name-field" + (formik.errors.name && formik.touched.name ? ' is-invalid' : '')}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.name && formik.errors.name ? (
                                                <p className="errorMessage">{formik.errors.name}</p>
                                            ) : null}
                                        </div>
                                        <div className="full-form-wrapper">
                                            <TextField
                                                label="Slug"
                                                type="text"
                                                variant="outlined"
                                                className={"slug-field" + (formik.errors.slug && formik.touched.slug ? ' is-invalid' : '')}
                                                name="slug"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.slug && formik.errors.slug ? (
                                                <p className="errorMessage">{formik.errors.slug}</p>
                                            ) : null}
                                        </div>
                                        <div className="full-form-wrapper picture">
                                            <div className="picture-choose">
                                                <input
                                                    type="file"
                                                    id="imageUpload"
                                                    accept=".png, .jpg, .jpeg, .gif"
                                                    ref={fileInput}
                                                    name="file"
                                                    onChange={(e)=>{
                                                        handleChange(e);
                                                    }}
                                                    //onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    style={{visibility : picture == undefined ? "visible" : "hidden" }}
                                                />
                                                <label htmlFor="imageUpload">
                                                    <Icon icon={cameraPlusOutline}/>
                                                    <span>Ajouter une image</span>
                                                </label>
                                            </div>
                                            {
                                                (picture != undefined) && <div className="picture-default">
                                                    <img src={picture} />
                                                </div>
                                            }
                                            {formik.touched.file && formik.errors.file ? (
                                                <p className="errorMessage">{formik.errors.file}</p>
                                            ) : null}
                                        </div>
                                        <div className="full-form-wrapper">
                                            <FormControlLabel
                                                control={
                                                    <div>
                                                        <Checkbox
                                                            name="published"
                                                            color="secondary"
                                                            id="published"
                                                            defaultChecked={true}
                                                            onChange={formik.handleChange}
                                                            value={formik.values.published}
                                                        />
                                                    </div>
                                                }
                                                label={<label htmlFor="published">Publier</label>} />
                                        </div>
                                    </form>
                                </div>
                            </DialogContent>
                            <DialogActions className="confirmation-button">
                                <Button onClick={handleCloseAddCountry} color="primary">
                                    Annuler
                                </Button>
                                <Button type="submit" onClick={formik.handleSubmit} disabled={formik.isSubmitting} color="primary">
                                    Ajouter
                                    {
                                        formik.isSubmitting ?
                                            (<CircularProgress />) : ('')
                                    }
                                </Button>
                            </DialogActions>
                        </div>
                    </Dialog>
                </div>
                {/* Countries search form */}
                <div className="page-header-search">
                    <TextField
                        /*label="Recherche"*/
                        placeholder="Recherche"
                        type="text"
                        variant="outlined"
                        className="search-field"
                        name="search"
                        id="search"
                        InputProps={{
                            startAdornment: <SearchIcon/>
                        }}
                    />
                    <Button>Chercher</Button>
                </div>
            </div>
            {/* Countries data grid */}
            <div className="countires-list">
                <div className="grid-wrapper" style={{ display: 'block', width: '100%' }}>
                    <DataGrid
                        rows={countries}
                        columns={columns}
                        pageSize={10}
                        autoHeight={true}
                        disableSelectionOnClick={true}
                        components={{
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                    />
                </div>
            </div>
            <DeleteDialog message={deletionDialogContent} isOpen={isOpen} submit={submitFunction} handleClose={handleClickCloseDeleteDialog} />
            <Dialog
                className="dialog large"
                open={updateCountry}
                onClose={handleCloseUpdateCountry}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <IconButton aria-label="close" className="closeButton" onClick={handleCloseUpdateCountry}>
                    <MdClose />
                </IconButton>
                <div className="change-container custom-modal">
                    <DialogTitle id="dialog-title">{"Modifier ce pays"}</DialogTitle>
                    <DialogContent>
                        <div className="dialog-container">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="full-form-wrapper">
                                    <FormControl
                                        className={"select-currency" + (formik.errors.currency_id && formik.touched.currency_id ? ' is-invalid' : '')}
                                    >
                                        <InputLabel id="category-label">Selectionner une devise</InputLabel>
                                        <Select
                                            name="currency_id"
                                            id="currency"
                                            labelId="category-label"
                                            variant="outlined"
                                            size="small"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            defaultValue={rowData.currency}
                                        >
                                            {
                                                currencies.map((currency) => (
                                                        <MenuItem key={currency.id} value={currency.id}>{currency.name}</MenuItem>
                                                    )
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                    {formik.touched.currency_id && formik.errors.currency_id ? (
                                        <p className="errorMessage">{formik.errors.currency_id}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper">
                                    <TextField
                                        name="num_order"
                                        label="Ajouter le numéro d'ordre"
                                        type="number"
                                        variant="outlined"
                                        className={"num-field" + (formik.errors.num_order && formik.touched.num_order ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={rowData.numOrder}
                                    />
                                    {formik.touched.num_order && formik.errors.num_order ? (
                                        <p className="errorMessage">{formik.errors.num_order}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper">
                                    <TextField
                                        name="name"
                                        label="Nom du pays"
                                        type="text"
                                        variant="outlined"
                                        className={"name-field" + (formik.errors.name && formik.touched.name ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={rowData.country}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <p className="errorMessage">{formik.errors.name}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper">
                                    <TextField
                                        label="Slug"
                                        type="text"
                                        variant="outlined"
                                        className={"slug-field" + (formik.errors.slug && formik.touched.slug ? ' is-invalid' : '')}
                                        name="slug"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={rowData.slug}
                                    />
                                    {formik.touched.slug && formik.errors.slug ? (
                                        <p className="errorMessage">{formik.errors.slug}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper picture">
                                    <div className="picture-choose">
                                        <input
                                            type="file"
                                            id="imageUpload"
                                            accept=".png, .jpg, .jpeg, .gif"
                                            ref={fileInputFlag}
                                            name="file"
                                            onChange={(e)=>{
                                                handleChangeFlag(e);
                                            }}
                                            //onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            style={{visibility : pictureFlag == undefined ? "visible" : "hidden" }}
                                        />
                                        <label htmlFor="imageUpload">
                                            <Icon icon={cameraPlusOutline}/>
                                            <span>Ajouter une image</span>
                                        </label>
                                    </div>
                                    {
                                        (pictureFlag != undefined) ?
                                            <div className="picture-default">
                                                <img src={pictureFlag} />
                                            </div> :
                                        <div className="picture-default">
                                        <img src={rowData.countryFlag} />
                                        </div>
                                    }
                                    {formik.touched.file && formik.errors.file ? (
                                        <p className="errorMessage">{formik.errors.file}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper published">
                                    <FormControlLabel
                                        control={
                                            <div>
                                                <Checkbox
                                                    name="published"
                                                    color="secondary"
                                                    id="published"
                                                    defaultChecked={true}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.published}
                                                />
                                            </div>
                                        }
                                        label={<label htmlFor="published">Publier</label>} />
                                </div>
                            </form>
                        </div>
                    </DialogContent>
                    <DialogActions className="confirmation-button">
                        <Button onClick={()=>{
                            handleCloseUpdateCountry();
                            setPictureFlag('');
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button type="submit" onClick={handleCloseUpdateCountry} color="primary">
                            Modifier
                        </Button>
                        {/*<Button type="submit" onClick={formik.handleSubmit} disabled={formik.isSubmitting} color="primary">
                            Modifier
                            {
                                formik.isSubmitting ?
                                    (<CircularProgress />) : ('')
                            }
                        </Button>*/}
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}