import React from 'react';
import {
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import NotFound from '../NotFound';
import './App.scss';
import AdminLoginPage from "../Admin/Login/AdminLoginPage";
import AdminDashboard from "../Admin/Dashboard/AdminDashboard";
import GuardedRoute from "../../security/GuardedRoute";

const App = ({ isAuthenticated }) => {

    return (
        <Switch>
            {/* Default */}
            <Route exact path="/" component={AdminLoginPage} />
      
            {/* NotFound */}
            <Route path="/not-found" component={NotFound} />

            {/* les routes protegées */}

            <GuardedRoute path="/dashboard" component={AdminDashboard} auth={isAuthenticated} />

            {/* Si la route n'existe pas ou n'est pas référencée */}
            <Route>
                <Redirect to="/not-found" />
            </Route>
        </Switch>
    );
}

export default App;
