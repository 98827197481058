import { connect } from 'react-redux';
import App from '../components/App';

const mapStateToProps = state => ({
  currentCountry: state.country.currentCountry,
  isAuthenticated : state.user.isAuthenticated,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);