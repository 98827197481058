import React from "react";
import './adminLoginPage.scss';
import {Button, TextField} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import logo from "../../../imgs/favicon.svg";
import {useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as Yup from 'yup';
import {authenticateUser} from "../../../services/users";
import {saveUser} from "../../../reducers/userReducer";
import {setAssignedCountries, setCurrentCountry} from "../../../reducers/countryReducer";
import {setMessage, setOpen} from "../../../reducers/snackbarReducer";
import {NavLink, Redirect} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function AdminLoginPage() {

    const dispatch = useDispatch();

    let isAuthenticated = useSelector(state => state.user.isAuthenticated);

    const togglePassword = ({ currentTarget }) => {
        currentTarget.classList.toggle('active');
        const passwordInput = document.getElementById('password');

        if (currentTarget.classList.contains('active')) {
            passwordInput.type = 'text';
        }
        else {
            passwordInput.type = 'password';
        }
    }

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .required('Ce champ est requis'),
            password: Yup.string()
                .required('Ce champ est requis'),
        }),
        onSubmit: (values, actions) => {
            let user = {...values};
            authenticateUser(user.username, user.password).then(({data})=> {
                console.log(data);
                if (data.RESPONSECODE == 200) {
                    let result = data.RESPONSEDATA.user_data;
                    dispatch(saveUser({ user : result }));
                    dispatch(setAssignedCountries({countries : result.countries }));
                    dispatch(setCurrentCountry({country : result.countries[0] }));
                    dispatch(setMessage({message : data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                    formik.resetForm();
                    actions.setSubmitting(false);
                } else {
                    dispatch(setMessage({message : data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                    actions.setSubmitting(false);
                }
            }).catch((e)=>{
                dispatch(setMessage({message : "Erreur lors de la création"}));
                dispatch(setOpen({open : true}));
                console.error(e);
            }).finally((e)=> {
                console.log(e);
            })
        },
    });

    if(isAuthenticated === true)
        return <Redirect to="/dashboard/members" />

    return (
        <div className="admin-login-content">
            <form className="admin-login-content-form" onSubmit={formik.handleSubmit}>
                <div className="logo-container">
                    <h1 className="logo-container-logo">
                        <span className="logo-line">taari</span>
                        <span className="logo-second-line">Admin</span>
                    </h1>
                </div>
                <strong className="admin-login-content-form-title">Bienvenue</strong>
                <strong className="admin-login-content-form-subtitle">Accéder à votre compte</strong>
                <div className="full-form-wrapper">
                    <TextField
                        name="username"
                        label="Identifiant"
                        type="text"
                        id="username"
                        variant="outlined"
                        className={"login-form-field" + (formik.errors.username && formik.touched.username ? ' is-invalid' : '')}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.username}
                    />
                    {formik.touched.username && formik.errors.username ? (
                        <p className="errorMessage">{formik.errors.username}</p>
                    ) : null}
                </div>
                <div className="full-form-wrapper password-input-item">
                    <TextField
                        name="password"
                        label="Saisissez votre mot de passe"
                        type="password"
                        id="password"
                        variant="outlined"
                        className={"password-form-field" + (formik.errors.password && formik.touched.password ? ' is-invalid' : '')}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.password}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <p className="errorMessage">{formik.errors.password}</p>
                    ) : null}
                    <span className="show-password" onClick={togglePassword}><VisibilityIcon /></span>
                </div>
                <div className="admin-login-content-form-line">
                    <Button type="submit" disabled={formik.isSubmitting} className="login-form-button" variant="contained" color="primary">
                        Connexion
                        {
                            formik.isSubmitting ?
                                (<CircularProgress />) : ('')
                        }
                    </Button>
                </div>
            </form>
        </div>
    );
}
