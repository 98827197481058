import { createGlobalStyle } from 'styled-components';
import airstream from './AirstreamNF.ttf';
import avenir from './AvenirLTStd-Medium.otf';

export default createGlobalStyle`
  @font-face {
    font-family: 'airstream-webfont';
    src: local('airstream-webfont'), local('airstream-webfont'),
    url(${airstream}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'avenir';
    src: local('avenir'), local('avenir'),
    url(${avenir}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
`;