import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  advertisements: [],
  advertisement: null,
  advertisementsSearchFilter : null,
  advertisementsLoaded: false,
  advertisementLoaded: false,

  createAdvertisement: {
    title: '',
    desc: '',
  },
};

const advertisementSlice = createSlice({
  name : 'advertisement',
  initialState : initialState,
  reducers : {
    getAdvertisementById : (state, action)=> {

    },

    getAdvertisementByCountryId : (state, action)=> {

    },

    getAdvertisements : (state, action)=> {
      
    },

    getAllAdvertisements : (state, action)=> {

    },
    
    getAdvertisement : (state, action)=> {

    },
    
    resetAdvertisement : (state, action) => {
      state.advertisement = null
    },

    resetAdvertisements : (state, action) => {
      state.advertisements = []
    },

    advertisementsLoading : (state, action) => {
      state.advertisementsLoaded = action.payload.status
    },

    advertisementLoading : (state, action) => {
      state.advertisementLoaded = action.payload.status
    },

    saveAdvertisements : (state, action) => {
      state.advertisements = action.payload.advertisements
    },

    saveAdvertisement : (state, action) => {
      state.advertisement = action.payload.advertisement
    },

    updateAdvertisementsSearchFilter : (state, action) => {
      state.advertisementsSearchFilter = action.payload.advertisementsSearchFilter
    }
  }
});

export const {advertisementsLoading, advertisementLoading, saveAdvertisement, saveAdvertisements,
                resetAdvertisements, resetAdvertisement, getAdvertisements, getAdvertisementById, getAllAdvertisements, 
                  getAdvertisement, getAdvertisementByCountryId, updateAdvertisementsSearchFilter } = advertisementSlice.actions;

export default advertisementSlice.reducer;
