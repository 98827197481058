import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  active: '',
  categories: [],
};

const countrySlice = createSlice({
  name : "category",
  initialState : initialState,
  reducers : {
    categorySelected : (state, action) => {
      state.currentCountry = action.payload.country;
      state.currentCity = null;
      state.activePanel = 'city';
    },

    saveCategories : (state, action) => {
      state.categories = [ ...action.payload.categories ]
    },

    saveAllCategories : (state, action) => {
      state.categories = action.payload.categories
    },

    getAllCategories : (state, action) => {
      
    }
  }
});

export const {saveAllCategories, saveCategories, categorySelected, getAllCategories} = countrySlice.actions;
export default countrySlice.reducer;
