import axios from 'axios';

import {
  apiUrl,
} from '../utils/axios';

import { getAllCountries, saveCountries, countryLoading, saveCities, getAllCities } from "../reducers/countryReducer";

const logMiddleware = (store) => (next) => (action) => {

    switch (action.type) {
        case getAllCountries().type:
            store.dispatch(countryLoading({ status : false}));
            axios.get(`${apiUrl}/get_all_countries`)
                .then(({ data }) => {
                    let countries = data.slice();
                    console.log(data);
                    store.dispatch(saveCountries({ countries : countries }));
                    store.dispatch(countryLoading({ status : true}));
                })
                .catch((e) => console.error(e));
            break;

        default:
            break;
    }

  next(action);
};

export default logMiddleware;
