import axios from 'axios';
import {
    apiUrl,
} from '../utils/axios';

export function getAllCities(countryId) {
    return axios.get(`${apiUrl}/admin_get_cities/${countryId}`);
}

export function getCityWithZones(id_city) {
    return axios.get(`${apiUrl}/admin_get_country_cities/${id_city}`)
}

/* Create */

export function createCity(data) {
    return axios.post(`${apiUrl}/admin_city_registration`, data);
}

/* Delete */

export function deleteCity(data) {
    return axios.post(`${apiUrl}/admin_deletion_city`, data);
}

/* Update */

export function updateCity(data) {
    return axios.post(`${apiUrl}/admin_city_update`, data);
}