import { combineReducers } from 'redux';

import userReducer from './userReducer';
import headerReducer from './headerReducer';
import countryReducer from './countryReducer';
import categoryReducer from './categoryReducer';
import advertisementReducer from './advertisementReducer';
import snackBarReducer from './snackbarReducer';

const reducers = combineReducers({
  user: userReducer,
  snackbar: snackBarReducer,
  header: headerReducer,
  country: countryReducer,
  category: categoryReducer,
  advertisement: advertisementReducer,
});

export default reducers;
