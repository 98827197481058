import axios from 'axios';
import {
    apiUrl,
} from '../utils/axios';

export function getAllCategories(){
    return axios.get(`${apiUrl}/admin_get_categories`);
}

/* Create category */

export function createCategory(categoryData){
    return axios.post(`${apiUrl}/admin_create_category`, categoryData);
}

/* Udpate category */

export function updateCategory(categoryData){
    return axios.post(`${apiUrl}/admin_update_category`, categoryData);
}

/* Delete category */

export function deleteCategory(categoryData){
    return axios.post(`${apiUrl}/admin_deletion_category`, categoryData);
}
