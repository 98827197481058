import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open : false,
  message : ""
};

const snackBarSlice = createSlice({
  name : 'snackbar',
  initialState: initialState,
  reducers : {
      setOpen : (state, action) => {
        state.open = action.payload.open
      },

      setMessage : (state, action) => {
        state.message = action.payload.message
      }
  }

});

export const { setOpen, setMessage } = snackBarSlice.actions;
export default snackBarSlice.reducer;
