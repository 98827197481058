import React, {useEffect, useState} from "react";
import './AdminBrands.scss';
import {Button, TextField} from "@material-ui/core";
import {DataGrid, GridOverlay, ValueFormatterParams} from '@material-ui/data-grid';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import Link from "@material-ui/core/Link";
import SearchIcon from "@material-ui/icons/Search";
import {deleteBrand, getAllBrands} from "../../../../services/brand";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CircularProgress from "@material-ui/core/CircularProgress";
import {DeleteDialog} from "../DeleteDialog/DeleteDialog";
import {useDispatch} from "react-redux";
import {setMessage, setOpen} from "../../../../reducers/snackbarReducer";
import BrandDialogComponent from "./brandDialogComponent/brandDialogComponent";
import {useParams} from "react-router-dom";

export default function AdminBrands() {

    /* Refresher function */

    function refreshPage() {
        window.location.reload();
    }

    let parameters = useParams();

    //console.log(parameters);

    const dispatch = useDispatch();

    const [anchorEls, setAnchorEls] = useState([]);
    const [brands, setBrands] = useState([]);
    const [dataLoading, isDataLoading] = useState(true);
    const [isOpenBrand, setIsOpenBrand] = useState(false);
    const [deletionDialogContent, setDeletionDialogContent] = useState("");
    const [submitFunction, setSubmitFunction] = useState(null);
    const [showBrandDialog, setShowBrandDialog] = useState(false);
    const [rowData, setRowData] = useState({});

    /* Actions constantes declared */

    const handleClick = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = event.target;
        setAnchorEls(aels);
    };

    const handleClose = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = null;
        setAnchorEls(aels);
    };

    /* Open the delete dialog */

    const handleClickOpenDeleteBrandDialog = (data) => {
        console.log(data);
        const brandDel = {
            "model_id": data.id_original
        }
        setSubmitFunction(() => ()=>{
            deleteBrand(brandDel).then((data)=> {
                if (data.data.RESPONSECODE == 200) {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                    refreshPage();
                } else {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                }
            }).catch((e)=>{
                dispatch(setMessage({message : "Erreur lors de la suppression"}));
                dispatch(setOpen({open : true}));
                console.error(e);
            }).finally((e)=> {
                console.log(e);
            });
            handleClickCloseDeleteBrandDialog();
        });
        setDeletionDialogContent("Êtes vous sûr de vouloir supprimer la marque " + data.brand)
        setIsOpenBrand(true);
    };

    const handleClickCloseDeleteBrandDialog = () => {
        setIsOpenBrand(false);
    };

    /* Update and add dialog */

    const openBrandDialog = (data) => {
        setRowData(data);
        setShowBrandDialog(true);
    }

    const closeBrandDialog = () => {
        setRowData({});
        setShowBrandDialog(false);
    }

    const brandData = {
        type: parameters.type
    }

    /* Fetch all brands data */

    useEffect(()=>{
        getAllBrands(brandData).then(({data})=>{
            //console.log(data.RESPONSEDATA);
            let res = [];
            //console.log(data);
            data.RESPONSEDATA.forEach((brand)=> {
                res.push({
                    id: brand.num_order,
                    id_original: brand.id,
                    brand: brand.name,
                    numOrder: brand.num_order,
                    models: '',
                    showAction: ''
                });
            })
            setBrands(res);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=>{
            //console.log(e);
            isDataLoading(false);
        })
    },[parameters.type]);

    /* Data Grid brands infos */

    const columns = [
        /*{ field: 'id', headerName: 'ID', width: 70 },*/
        { field: 'numOrder', headerName: 'Numéro d\'ordre', width: 140 },
        { field: 'brand', headerName: 'Marque', width: 300 },
        { field: 'showAction', headerName: 'Actions', width: 100,
            renderCell: (params) => (
                // params.rowModel
                <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={e => handleClick(params.rowModel.id, e)}>
                        Actions
                    </Button>
                    <Menu
                        id={params.rowModel.id}
                        anchorEl={anchorEls[params.rowModel.id]}
                        keepMounted
                        open={Boolean(anchorEls[params.rowModel.id])}
                        onClose={e => handleClose(params.rowModel.id, e)}
                    >
                        <Link href={`/dashboard/brands/${parameters.type}/${params.rowModel.data.id_original}/${parameters.brandName}/models`} onClick={e => handleClose(params.rowModel.id, e)}><VisibilityIcon/>Voir les modèles</Link>
                        <MenuItem onClick={(e) => {
                            handleClose(params.rowModel.id, e);
                            openBrandDialog(params.rowModel.data);
                        }}>
                            <CreateIcon/>Modifier
                        </MenuItem>
                        <MenuItem onClick={(e) => {
                            handleClose(params.rowModel.id, e);
                            handleClickOpenDeleteBrandDialog(params.rowModel.data, e);
                        }}><DeleteIcon/>Supprimer</MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    function checkType(type) {
        //console.log(advertTypeNumber);
        switch (type) {
            case '1':
                return ("Voitures")
            case '2':
                return ("Téléphones")
            case '3':
                return ("Motos")
            case '4':
                return ("Ordinateurs")
            case '5':
                return ("Camions")
            default:
                return ("Marque non trouvée.")
        }
    }

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                { dataLoading ? <div className="data-loading"> <CircularProgress /> Chargement en cours... </div> : <div>Pas de données</div> }
            </GridOverlay>
        );
    }

    return (
        <div className="brands">
            <h1>Marque {checkType(parameters.type)}</h1>
            <div className="page-header">
                <div className="page-header-add">
                    <Button onClick={openBrandDialog}>Ajouter une marque</Button>
                    <BrandDialogComponent showDialog={showBrandDialog} brand={rowData} handleClose={closeBrandDialog} brandType={parameters.type}/>
                </div>
                <div className="page-header-search">
                    <TextField
                        /*label="Recherche"*/
                        placeholder="Recherche"
                        type="text"
                        variant="outlined"
                        className="search-field"
                        name="search"
                        id="search"
                        InputProps={{
                            startAdornment: <SearchIcon/>
                        }}
                    />
                    <Button>Chercher</Button>
                </div>
            </div>
            <div className="brand-list">
                <div className="grid-wrapper" style={{ display: 'block', width: '100%' }}>
                    <DataGrid
                        rows={brands}
                        columns={columns}
                        pageSize={10}
                        autoHeight={true}
                        disableSelectionOnClick={true}
                        components={{
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                    />
                </div>
            </div>
            <DeleteDialog message={deletionDialogContent} isOpen={isOpenBrand} submit={submitFunction} handleClose={handleClickCloseDeleteBrandDialog} />
        </div>
    );
}