import React, {useEffect, useRef} from "react";
import './AdminHeader.scss';
import {NavLink} from "react-router-dom";
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import { Icon } from '@iconify/react';
import caretDown from '@iconify/icons-carbon/caret-down';
import bxsUser from '@iconify/icons-bx/bx-user';
import {
    FaUserCircle,
    FaChevronDown
} from 'react-icons/fa';
import IconButton from "@material-ui/core/IconButton";
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {TextField} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import {useDispatch, useSelector} from "react-redux";
import {logUserOut} from "../../../services/users";
import { setCurrentCountry } from "../../../reducers/countryReducer";
import {staticApiUrl} from '../../../utils/axios';

export default function AdminHeader () {

    const dispatch = useDispatch();

    const currentUser = useSelector(state => state.user.currentUser);

    let currentCountry = useSelector(state => state.country.currentCountry);

    let assignedCountries = useSelector(state => state.country.assignedCountries);

    let isAuthenticated = useSelector(state => state.user.isAuthenticated);

    let url = `${staticApiUrl}/countryimages/`;

    function logout() {
        logUserOut();
    }

    const [openMenu, setOpenMenu] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggleMenu = () => {
        setOpenMenu((prevOpen) => !prevOpen);
    };

    const handleCloseMenu = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenMenu(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpenMenu(false);
        }
    }

    /* Change user password modal */

    const [changePassword, setChangePassword] = React.useState(false);

    const handleOpenChangePassword = () => {
        setChangePassword(true);
    };

    const handleCloseChangePassword = () => {
        setChangePassword(false);
    };

    const [isOpenMenu, setIsOpenMenu] = React.useState(false);

    const toggling = () => setIsOpenMenu(!isOpenMenu);

    const onOptionClicked = value => () => {
        //   setSelectedOption(value);
        dispatch(setCurrentCountry({country : value }));
        setIsOpenMenu(false);
        window.location.reload();
    };

    //console.log(currentCountry);

    return (
        <header className="admin-header-content">
            <h1 className="admin-header-content-logo">
                <NavLink to="/">taari</NavLink>
                <span>Admin</span>
            </h1>
            {
                isAuthenticated === true ? (
                    <div className="menu-container">
                        <ul>
                            {
                                (currentUser.type == "5" || currentUser.type == "7") ?
                                    (
                                        <li className="admin-link-icon">
                                            <NavLink to={`/dashboard/create-admin`}>
                                                <PeopleAltOutlinedIcon/>
                                                <span>Administrateurs</span>
                                            </NavLink>
                                        </li>
                                    ) : ('')
                            }
                            <li className="user-profile">
                                <Button
                                    ref={anchorRef}
                                    aria-controls={openMenu ? 'user-simple-menu' : undefined}
                                    aria-haspopup="true"
                                    onClick={handleToggleMenu}>
                                    <Icon className="user-icon" icon={bxsUser} /> {currentUser ? currentUser.first_name : ""} <Icon className="user-caret" icon={caretDown} />
                                </Button>
                                <Popper className="user-popper" open={openMenu} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                    {({ TransitionProps, placement }) => (
                                        <Grow
                                            {...TransitionProps}
                                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                        >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleCloseMenu}>
                                                    <MenuList autoFocusItem={openMenu} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                        <div className="user-profile-inner">
                                                            <div className="user-profile-inner-top">
                                                                <div className="user-profile-inner-top-image">
                                                                    <FaUserCircle />
                                                                </div>
                                                                <div className="user-profile-inner-top-username">
                                                                    <h3>{currentUser ? currentUser.first_name : ""}</h3>
                                                                </div>
                                                            </div>
                                                            <div className="user-profile-inner-bottom">
                                                                <Button
                                                                    onClick={() => {
                                                                        handleCloseMenu();
                                                                        handleOpenChangePassword();
                                                                    }}>Changer mon mot de passe</Button>
                                                                <Dialog
                                                                    className="dialog large"
                                                                    open={changePassword}
                                                                    onClose={handleCloseChangePassword}
                                                                    aria-labelledby="alert-dialog-title"
                                                                    aria-describedby="alert-dialog-description">
                                                                    <IconButton aria-label="close" className="closeButton" onClick={handleCloseChangePassword}>
                                                                        <MdClose />
                                                                    </IconButton>
                                                                    <div className="change-container custom-modal">
                                                                        <DialogTitle id="dialog-title">{"Changer mon mot de page"}</DialogTitle>
                                                                        <DialogContent>
                                                                            <div className="dialog-container">
                                                                                <form>
                                                                                    <div className="full-form-wrapper">
                                                                                        <TextField
                                                                                            name="name"
                                                                                            label="Nom de la ville"
                                                                                            type="text"
                                                                                            variant="outlined"
                                                                                            /*className={"name-field" + (formik.errors.name && formik.touched.name ? ' is-invalid' : '')}
                                                                                            onChange={formik.handleChange}
                                                                                            onBlur={formik.handleBlur}*/
                                                                                        />
                                                                                        {/*{formik.touched.name && formik.errors.name ? (
                                                                                    <p className="errorMessage">{formik.errors.name}</p>
                                                                                ) : null}*/}
                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        </DialogContent>
                                                                        <DialogActions className="confirmation-button">
                                                                            <Button onClick={handleCloseChangePassword} color="primary">
                                                                                Annuler
                                                                            </Button>
                                                                            <Button type="submit" color="primary">
                                                                                Changer
                                                                                {/* {
                                                                            formik.isSubmitting ?
                                                                                (<CircularProgress />) : ('')
                                                                        }*/}
                                                                            </Button>
                                                                        </DialogActions>
                                                                    </div>
                                                                </Dialog>
                                                            </div>
                                                            <div className="user-profile-inner-footer">
                                                                <Button onClick={logout}>Déconnexion</Button>
                                                            </div>
                                                        </div>
                                                    </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                        </Grow>
                                    )}
                                </Popper>
                            </li>
                            <li className="countryMenu">
                                <div className={"dropdown" + (isOpenMenu ? " open" : "")}>
                                    <div className="dropdown-header" onClick={toggling}>
                                        {

                                            currentCountry &&

                                            <div>
                                                <img src={url + currentCountry.images[0].filename} alt={currentCountry.name}/>
                                                <span>{currentCountry.name}</span>
                                            </div>

                                        }
                                        <FaChevronDown/>
                                    </div>
                                    {
                                        isOpenMenu && (
                                            <div className="dropdown-body">
                                                <ul>
                                                    {assignedCountries && assignedCountries.map(option => (
                                                        <li onClick={onOptionClicked(option)} key={option.id}>
                                                            <img src={url + option.images[0].filename} alt={option.name} />
                                                            <span>{option.name}</span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )
                                    }
                                </div>
                            </li>
                        </ul>
                    </div>
                ) : ("")
            }
        </header>
    );
}