import axios from 'axios';
import {
    apiUrl,
} from '../utils/axios';


export function getAllZones(cityId) {
    return axios.get(`${apiUrl}/admin_get_city_zones/${cityId}`);
}

/* Create */

export function createZone(dataCreate) {
    return axios.post(`${apiUrl}/admin_zone_registration`, dataCreate);
}

/* Delete */

export function deleteZone(dataDelete) {
    return axios.post(`${apiUrl}/admin_deletion_zone`, dataDelete);
}

/* Update */

export function updateZone(dataUpdate) {
    return axios.post(`${apiUrl}/admin_zone_update`, dataUpdate);
}
