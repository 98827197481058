import axios from 'axios';
import {
    apiUrl,
} from '../utils/axios';

export function createAdminUser(adminData) {
    return axios.post(`${apiUrl}/admin_user_registration`, adminData);
}

export function deleteAdminUser(adminData) {
    return axios.post(`${apiUrl}/admin_deletion_user`, adminData);
}

export function updateAdminUser(adminData) {
    return axios.post(`${apiUrl}/admin_user_update`, adminData);
}

/*export function getAdminUser(adminData) {
    return axios.post(`${apiUrl}/admin_get_user_infos`, adminData);
}*/

export function getAllAdminUsers() {
    return axios.get(`${apiUrl}/admin_get_users`);
}

export function getUserTypes() {
    return axios.get(`${apiUrl}/admin_get_usertypes`);
}