import React, {useEffect, useState} from "react";
import './AdminSubCategories.scss';
import {Button, TextField} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import {DataGrid, GridOverlay} from "@material-ui/data-grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SearchIcon from "@material-ui/icons/Search";
import {getAllSubCategories, deleteSubCategory} from "../../../../services/subCategory";
import {useParams} from "react-router-dom";
import {setMessage, setOpen} from "../../../../reducers/snackbarReducer";
import {useDispatch} from "react-redux";
import {DeleteDialog} from "../DeleteDialog/DeleteDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import SubCategoryDialogComponent from "./subcategoryDialogComponent/subcategoryDialogComponent";
import CityDialogComponent from "../Cities/cityDialogComponent/cityDialogComponent";

export default function AdminSubCategories () {

    /* Refresher function */

    function refreshPage() {
        window.location.reload();
    }

    const dispatch = useDispatch();

    let params = useParams();
    let categoryId = params.id;

    const [anchorEls, setAnchorEls] = React.useState([]);
    const [category, setCategory] = React.useState(undefined);
    const [subCategories, setSubCategories] = React.useState([]);
    const [dataLoading, isDataLoading] = React.useState(true);
    const [showSubCategoryDialog, setShowSubCategoryDialog] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [deletionDialogContent, setDeletionDialogContent] = useState("");
    const [submitFunction, setSubmitFunction] = useState(null);
    const [rowData, setRowData] = React.useState([]);

    const handleClick = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = event.target;
        setAnchorEls(aels);
        //  setAnchorEl(event.currentTarget);
    };

    const handleClose = (id, event) => {
        // setAnchorEl(null);
        let aels = anchorEls.slice();
        aels[id] = null;
        setAnchorEls(aels);
    };

    /* Update and add dialog */

    const openSubCategoryDialog = (data) => {
        console.log(data);
        setRowData(data);
        setShowSubCategoryDialog(true);
    }

    const closeSubCategoryDialog = () => {
        setShowSubCategoryDialog(false);
    }

    /* Fetch all subcategories data */

    useEffect(()=>{
        getAllSubCategories(categoryId).then(({data})=> {
            //console.log(data);
            let res = [];
            let subCategories = data[0].sub;
            subCategories.forEach((subCategory)=>{
                res.push({
                    id: subCategory.num_order,
                    id_original: subCategory.id,
                    subCategory: subCategory.title_fr,
                    numOrder: subCategory.num_order,
                    showAction: ''
                });
            })
            setSubCategories(res);
            setCategory(data[0]);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
            isDataLoading(false);
        })
    },[]);

    /* Delete sub categorie */

    const handleOpenDeleteSubCategory = (data) => {
        console.log(data);
        const categoryDel = {
            "category_id": data.id_original
        }
        setSubmitFunction(() => ()=>{
            deleteSubCategory(categoryDel).then((data)=> {
                if (data.data.RESPONSECODE == 200) {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                    refreshPage();
                } else {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                }
            }).catch((e)=>{
                dispatch(setMessage({message : "Erreur lors de la suppression"}));
                dispatch(setOpen({open : true}));
                console.error(e);
            }).finally((e)=> {
                console.log(e);
                refreshPage();
            });
            //handleCloseDeleteCategory();
        });
        setDeletionDialogContent("Êtes vous sûr de vouloir supprimer la sous catégorie " + data.subCategory)
        setIsOpen(true);
    };

    const handleCloseDeleteSubCategory = () => {
        setIsOpen(false);
    };

    /* Data Grid header infos */

    const columns = [
        /*{ field: 'id', headerName: 'ID', width: 70 },*/
        { field: 'numOrder', headerName: 'Numéro d\'ordre', width: 140,},
        { field: 'subCategory', headerName: 'Téléphones', width: 400 },
        { field: 'showAction', headerName: 'Actions', width: 100,
            renderCell: (params) => (
                <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={e => handleClick(params.rowModel.id, e)}>
                        Actions
                    </Button>
                    <Menu
                        id={params.rowModel.id}
                        anchorEl={anchorEls[params.rowModel.id]}
                        keepMounted
                        open={Boolean(anchorEls[params.rowModel.id])}
                        onClose={e => handleClose(params.rowModel.id, e)}
                    >
                        <MenuItem onClick={(e) => {
                            handleClose(params.rowModel.id, e);
                            openSubCategoryDialog(params.rowModel.data);
                        }}>
                            <CreateIcon/>Modifier</MenuItem>
                        <MenuItem onClick={(e) => {
                            handleClose(params.rowModel.id, e);
                            handleOpenDeleteSubCategory(params.rowModel.data);
                        }}>
                            <DeleteIcon/>Supprimer
                        </MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                { dataLoading ? <div className="data-loading"> <CircularProgress /> Chargement en cours... </div> : <div>Pas de données</div> }
            </GridOverlay>
        );
    }

    return (
        <div className="admin-content-wrapper sub-categories">
            <div className="admin-content-wrapper-title">
                <IconButton href={`/dashboard/categories`}>
                    <ArrowBackIcon />
                </IconButton>
                <h2>Details catégorie</h2>
            </div>
            <div className="item-details">
                <div className="item-details-infos">
                    <ul>
                        <li>Catégorie :</li>
                        {category && <li>{category.title_fr}</li>}
                    </ul>
                    <ul>
                        <li>Ordre :</li>
                        {category && <li>{category.num_order}</li>}
                    </ul>
                    <ul>
                        <li>Date de modification :</li>
                        {category && <li>{category.updated_at}</li>}
                    </ul>
                </div>
            </div>
            <div className="page-header">
                <div className="page-header-add">
                    <Button onClick={openSubCategoryDialog}>Ajouter une sous catégorie</Button>
                    <SubCategoryDialogComponent showDialog={showSubCategoryDialog} subcategory={rowData} categoryId={categoryId} handleClose={closeSubCategoryDialog} />
                </div>
                <div className="page-header-search">
                    <TextField
                        /*label="Recherche"*/
                        placeholder="Recherche"
                        type="text"
                        variant="outlined"
                        className="search-field"
                        name="search"
                        id="search"
                        InputProps={{
                            startAdornment: <SearchIcon/>
                        }}
                    />
                    <Button>Chercher</Button>
                </div>
            </div>
            <div className="countires-list">
                <div className="grid-wrapper" style={{ display: 'block', width: '100%' }}>
                    <DataGrid
                        rows={subCategories}
                        columns={columns}
                        pageSize={10}
                        autoHeight={true}
                        disableSelectionOnClick={true}
                        components={{
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                    />
                </div>
            </div>
            <DeleteDialog message={deletionDialogContent} isOpen={isOpen} submit={submitFunction}  handleClose={handleCloseDeleteSubCategory} />
        </div>
    )
}