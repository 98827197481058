import React, {useEffect} from "react";
import IconButton from "@material-ui/core/IconButton";
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, TextField} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import {useFormik} from "formik";
import * as Yup from "yup";
import {setMessage, setOpen} from "../../../../../reducers/snackbarReducer";
import {useDispatch} from "react-redux";
import {createModel, updateModel} from "../../../../../services/model";

export default function ModelDialogComponent ({showDialog, model, brandId, brandType, handleClose}) {

    /* Refresher function */

    function refreshPage() {
        window.location.reload();
    }

    const dispatch = useDispatch();

    /* Add or update a new city */

    let formik = useFormik({
        initialValues: {
            name: undefined,
            parent_id: brandId,
            num_order: undefined,
            published: 1,
            type: brandType
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .required('Ce champ est requis'),
            num_order: Yup.string()
                .required('Ce champ est requis')
        }),

        onSubmit: (values, actions) => {
            let modelInfos = {...values};
            modelInfos.published = modelInfos.published == false ? 0 : 1;
            if (model.id_original) {
                //console.log(modelInfos);
                updateModel(modelInfos).then(({data})=> {
                    dispatch(setMessage({message : "Modèle mis à jour avec succès"}));
                    dispatch(setOpen({open : true}));
                    //console.log(data);
                    formik.resetForm();
                }).catch((e)=>{
                    dispatch(setMessage({message : "Erreur lors de la création"}));
                    dispatch(setOpen({open : true}));
                    console.error(e);
                }).finally((e)=>{
                    console.log(e);
                    actions.setSubmitting(false);
                    refreshPage();
                })
            } else {
                //console.log(modelInfos);
                createModel(modelInfos).then(({data})=> {
                    dispatch(setMessage({message : "Modèle crée avec succès"}));
                    dispatch(setOpen({open : true}));
                    formik.resetForm();
                    refreshPage();
                }).catch((e)=>{
                    dispatch(setMessage({message : "Erreur lors de la création"}));
                    dispatch(setOpen({open : true}));
                    console.error(e);
                }).finally((e)=>{
                    console.log(e);
                    actions.setSubmitting(false);
                    refreshPage();
                })
            }
        },
    });

    useEffect(()=>{
        if(model.id){
            formik.values.name = model.model;
            formik.values.parent_id = brandId;
            formik.values.num_order = model.numOrder;
            formik.values.model_id = model.id_original;
            formik.values.type = brandType
        }else {
            formik.values.name = undefined;
            formik.values.parent_id = brandId;
            formik.values.num_order = undefined;
        }
    }, [model]);

    return (
        <div className="city-dialog">
            <Dialog
                className="dialog large"
                open={showDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <IconButton aria-label="close" className="closeButton" onClick={handleClose}>
                    <MdClose />
                </IconButton>
                <div className="change-container custom-modal">
                    <DialogTitle id="dialog-title">{model.id ? "Modification d'un modèle" : "Création d'un modèle" }</DialogTitle>
                    <DialogContent>
                        <div className="dialog-container">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="full-form-wrapper">
                                    <TextField
                                        name="name"
                                        label="Nom du modèle"
                                        type="text"
                                        variant="outlined"
                                        className={"name-field" + (formik.errors.name && formik.touched.name ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={model.model}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <p className="errorMessage">{formik.errors.name}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper">
                                    <TextField
                                        name="num_order"
                                        label="Ajouter le numéro d'ordre"
                                        type="number"
                                        variant="outlined"
                                        className={"num-field" + (formik.errors.num_order && formik.touched.num_order ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={model.numOrder}
                                    />
                                    {formik.touched.num_order && formik.errors.num_order ? (
                                        <p className="errorMessage">{formik.errors.num_order}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper published">
                                    <FormControlLabel
                                        control={
                                            <div>
                                                <Checkbox
                                                    name="published"
                                                    color="secondary"
                                                    id="published"
                                                    defaultChecked={true}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.published}
                                                />
                                            </div>
                                        }
                                        label={<label htmlFor="published">Publier</label>} />
                                </div>
                            </form>
                        </div>
                    </DialogContent>
                    <DialogActions className="confirmation-button">
                        <Button onClick={() => {
                            formik.resetForm()
                            handleClose();
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button type="submit" onClick={formik.handleSubmit} disabled={formik.isSubmitting} color="primary">
                            {model.id ? "Modifier" : "Créer" }
                            {
                                formik.isSubmitting ?
                                    (<CircularProgress />) : ('')
                            }
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}