import React from "react";
import './AdminDiscussions.scss';
import {Button, TextField} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import Link from "@material-ui/core/Link";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MenuItem from "@material-ui/core/MenuItem";
import RemoveCircleOutlinedIcon from "@material-ui/icons/RemoveCircleOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import {DataGrid, GridOverlay} from "@material-ui/data-grid";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";

export default function AdminDiscussions() {

    /* Member Each Menu view */

    const [anchorEls, setAnchorEls] = React.useState([]);
    const [dataLoading, isDataLoading] = React.useState(true);

    /* Actions constantes declared */

    const handleClick = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = event.target;
        setAnchorEls(aels);
    };

    const handleClose = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = null;
        setAnchorEls(aels);
    };

    /* Data Grid header infos */

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'author', headerName: 'Auteur', width: 200 },
        { field: 'receiver', headerName: 'Destinataire', width: 200 },
        { field: 'date', headerName: 'Date', width: 120 },
        { field: 'showAction', headerName: 'Actions', width: 100,
            renderCell: (params) => (
                <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={e => handleClick(params.rowModel.id, e)}>
                        Actions
                    </Button>
                    <Menu
                        id={params.rowModel.id}
                        anchorEl={anchorEls[params.rowModel.id]}
                        keepMounted
                        open={Boolean(anchorEls[params.rowModel.id])}
                        onClose={e => handleClose(params.rowModel.id, e)}
                    >
                        <Link href={`/dashboard/discussions/${params.rowModel.data.id}/message`} onClick={e => handleClose(params.rowModel.id, e)}><VisibilityIcon/>Voir</Link>
                        <MenuItem onClick={(e) => {
                            handleClose(params.rowModel.id, e);
                        }}>
                            <DeleteIcon/>Supprimer
                        </MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    /* No data display */

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                { dataLoading ? <div className="data-loading"> <CircularProgress /> Chargement en cours... </div> : <div>Pas de données</div> }
            </GridOverlay>
        );
    };

    const rows = [
        { id: 1, author: 'Jon', receiver: 'Snow', date: '11/12/2030'},
    ];

    return (
        <div className="messages-list">
            <h1>Discussions</h1>
            <div className="page-header">
                <div className="page-header-search">
                    <TextField
                        /*label="Recherche"*/
                        placeholder="Recherche"
                        type="text"
                        variant="outlined"
                        className="search-field"
                        name="search"
                        id="search"
                        InputProps={{
                            startAdornment: <SearchIcon/>
                        }}
                    />
                    <Button>Chercher</Button>
                </div>
            </div>
            <div className="grid-wrapper" style={{ display: 'block', width: '100%' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    autoHeight={true}
                    disableSelectionOnClick={true}
                    components={{
                        noRowsOverlay: CustomNoRowsOverlay,
                    }}
                />
            </div>
        </div>
    );
}