import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated : false,
  userLoadState : "NOT LOADED",
  currentUser : null
};

const userSlice = createSlice({
  name : 'user',
  initialState: initialState,
  reducers : {
      saveUser : (state, action) => {
        state.currentUser = action.payload.user
        state.isAuthenticated = true
      },

      disconnectUser : (state) => {
        state.currentUser = null
        state.isAuthenticated = false
      },

      updateUserLoadState : (state, action) => {
        state.userLoadState = action.payload.userLoadState;
      }
  }

});

export const { saveUser, disconnectUser, updateUserLoadState} = userSlice.actions;
export default userSlice.reducer;
