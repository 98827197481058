import axios from 'axios';
import {
    apiUrl,
} from '../utils/axios';

export function getAllIcons(){
    return axios.get(`${apiUrl}/admin_get_icons`);
}

/* Create icon */
export function createIcon(iconData){
    return axios.post(`${apiUrl}/admin_create_icon`, iconData);
}

/* delete icon */
export function deleteIcon(iconData){
    return axios.post(`${apiUrl}/admin_deletion_icon`, iconData);
}

/* update icon */
export function updateIcon(iconData){
    return axios.post(`${apiUrl}/`, iconData);
}