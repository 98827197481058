import React, {useState} from "react";
import './AdminDiscussionsDetails.scss';
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import Link from "@material-ui/core/Link";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MenuItem from "@material-ui/core/MenuItem";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import {DataGrid, GridOverlay} from "@material-ui/data-grid";

export default function AdminDiscussionsDetails() {

    const [anchorEls, setAnchorEls] = React.useState([]);

    /* message each menu */

    const handleClick = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = event.target;
        setAnchorEls(aels);
    };

    const handleClose = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = null;
        setAnchorEls(aels);
    };

    /* Message data grid header infos */

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'from', headerName: 'De', width: 200 },
        { field: 'to', headerName: 'Destinataire', width: 200 },
        { field: 'message', headerName: 'Message', width: 200 },
        { field: 'file', headerName: 'Fichier', width: 120 },
        { field: 'date', headerName: 'Date', width: 150 },
    ];

    const rows = [
        { id: 1, from: 'Jon', to: 'Snow', message: 'Je suis le message qui va etre afficher', file: '', date: '11/12/2030'},
    ];

    /* No data display */

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                <div>Pas de données</div>
            </GridOverlay>
        );
    }

    return (
        <div className="discussions-details">
            <div className="admin-content-wrapper-title">
                <div className="admin-content-wrapper-title-group">
                    <IconButton href={`/dashboard/countries`}>
                        <ArrowBackIcon />
                    </IconButton>
                    <h2>Vente de PC portatif Dell</h2>
                </div>
                <Button>Supprimer</Button>
            </div>
            <div className="discussions-details-infos">
                <ul>
                    <li>Auteur :</li>
                    <li>John</li>
                </ul>
                <ul>
                    <li>Date de création :</li>
                    <li></li>
                </ul>
                <ul>
                    <li>Destinataire :</li>
                    <li></li>
                </ul>
                <ul>
                    <li>Date de modification :</li>
                    <li></li>
                </ul>
            </div>
            <div className="message-list">
                <div className="grid-wrapper" style={{ display: 'block', width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        autoHeight={true}
                        disableSelectionOnClick={true}
                        components={{
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                    />
                </div>
            </div>
        </div>
    )
}