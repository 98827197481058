import React, {useEffect, useState} from "react";
import './AdminColors.scss';
import {Button, TextField} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import {DeleteDialog} from "../../DeleteDialog/DeleteDialog";
import {DataGrid, GridOverlay} from "@material-ui/data-grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import {setMessage, setOpen} from "../../../../../reducers/snackbarReducer";
import {useDispatch} from "react-redux";
import CreateIcon from "@material-ui/icons/Create";
import {deleteColor, getAllColors} from "../../../../../services/color";
import ColorDialogComponent from "./colorDialogComponent/colorDialogComponent";

export default function AdminColors() {

    function refreshPage() {
        window.location.reload();
    }

    const dispatch = useDispatch();

    /* Open the delete dialog */

    const [isOpenColor, setIsOpenColor] = useState(false);
    const [deletionColorDialogContent, setDeletionColorDialogContent] = useState("");
    const [submitColorFunction, setSubmitColorFunction] = useState(null);
    const [anchorEls, setAnchorEls] = useState([]);
    const [colors, setColors] = useState([]);
    const [dataLoading, isDataLoading] = useState(true);
    const [showColorDialog, setShowColorDialog] = useState(false);
    const [rowDataColors, setRowDataColors] = useState({});

    /* Color delete */
    const handleClickOpenDeleteColorDialog = (data) => {
        console.log(data);
        const colorDel = {
            "color_id": data.id
        }
        setSubmitColorFunction(() => ()=>{
            deleteColor(colorDel).then((data)=> {
                if (data.data.RESPONSECODE == 200) {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                    refreshPage();
                } else {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                }
            }).catch((e)=>{
                dispatch(setMessage({message : "Erreur lors de la suppression"}));
                dispatch(setOpen({open : true}));
                console.error(e);
            }).finally((e)=> {
                console.log(e);
            });
            handleClickCloseDeleteColorDialog();
        });
        setDeletionColorDialogContent("Êtes vous sûr de vouloir supprimer la couleur " + data.colorName)
        setIsOpenColor(true);
    }

    const handleClickCloseDeleteColorDialog = () => {
        setIsOpenColor(false);
    };

    /* Color Each Menu view */
    const handleClickColor = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = event.target;
        setAnchorEls(aels);
    };

    const handleCloseColor = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = null;
        setAnchorEls(aels);
    };

    /* Update and add color dialog */
    const openColorDialog = (data) => {
        //console.log(data);
        setRowDataColors(data);
        setShowColorDialog(true);
    }

    const closeColorDialog = () => {
        setRowDataColors({});
        setShowColorDialog(false);
    }

    /* Fetch all Colors data */
    useEffect(()=>{
        getAllColors().then(({data})=> {
            //console.log(data);
            let res = [];
            data.forEach((color)=> {
                res.push({
                    id: color.id,
                    colorName: color.name,
                    colorCode: color.code,
                    numOrder: color.num_order,
                    showAction: ''
                });
            })
            setColors(res);
            //console.log(res);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
            isDataLoading(false);
        })
    },[]);

    /* Data Grid header infos */

    const columnsColors = [
        { field: 'id', headerName: 'ID'},
        /*{ field: 'numOrder', headerName: 'Numéro d\'ordre', width: 150},*/
        { field: 'colorName', headerName: 'Nom de la couleur', width: 150 },
        { field: 'colorCode', headerName: 'Code couleur', width: 150},
        { field: 'showAction', headerName: 'Actions', width: 120,
            renderCell: (params) => (
                <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={e => handleClickColor(params.rowModel.id, e)}>
                        Actions
                    </Button>
                    <Menu
                        id={params.rowModel.id}
                        anchorEl={anchorEls[params.rowModel.id]}
                        keepMounted
                        open={Boolean(anchorEls[params.rowModel.id])}
                        onClose={e => handleCloseColor(params.rowModel.id, e)}
                    >
                        <MenuItem onClick={(e) => {
                            handleCloseColor(params.rowModel.id, e);
                            openColorDialog(params.rowModel.data, e);
                        }}>
                            <CreateIcon/>Modifier
                        </MenuItem>
                        <MenuItem onClick={(e) => {
                            handleCloseColor(params.rowModel.id, e);
                            handleClickOpenDeleteColorDialog(params.rowModel.data);
                        }}>
                            <DeleteIcon/>Supprimer
                        </MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    /* No data display */

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                { dataLoading ? <div className="data-loading"> <CircularProgress /> Chargement en cours... </div> : <div>Pas de données</div> }
            </GridOverlay>
        );
    };

    return (
        <div className="colors-wrapper">
            <h2>Ajout de couleur pour les marques</h2>
            <div className="colors">
                <div className="page-header">
                    <div className="page-header-add">
                        <Button onClick={openColorDialog}>Ajouter une couleur</Button>
                        <ColorDialogComponent showDialog={showColorDialog} color={rowDataColors} handleCloseColor={closeColorDialog} />
                    </div>
                    {/* Color search form */}
                    <div className="page-header-search">
                        <TextField
                            /*label="Recherche"*/
                            placeholder="Recherche"
                            type="text"
                            variant="outlined"
                            className="search-field"
                            name="search"
                            id="search"
                            InputProps={{
                                startAdornment: <SearchIcon/>
                            }}
                        />
                        <Button>Chercher</Button>
                    </div>
                </div>
                {/* Colors data grid */}
                <div className="Colors-list">
                    <div className="colors-data-grid">
                        <DataGrid
                            rows={colors}
                            columns={columnsColors}
                            pageSize={10}
                            autoHeight={true}
                            disableSelectionOnClick={true}
                            components={{
                                noRowsOverlay: CustomNoRowsOverlay,
                            }}
                        />
                    </div>
                </div>
            </div>
            <DeleteDialog message={deletionColorDialogContent} isOpen={isOpenColor} submit={submitColorFunction} handleClose={handleClickCloseDeleteColorDialog} />
        </div>
    );
}