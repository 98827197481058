import axios from 'axios';
import {
    apiUrl,
} from '../utils/axios';

export function getAllMembers(membersData) {
    return axios.post(`${apiUrl}/admin_get_front_users`, membersData);
}

export function getMember(memberData) {
    return axios.post(`${apiUrl}/admin_get_user_front_infos`, memberData);
}

export function deleteMember(memberData) {
    return axios.post(`${apiUrl}/admin_suspend_front_user`, memberData);
}

export function suspendMember(memberData) {
    return axios.post(`${apiUrl}/admin_suspend_front_user`, memberData);
}

export function updateMember(memberData) {
    return axios.post(`${apiUrl}/`, memberData);
}

