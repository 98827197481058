import React, {useEffect, useState} from "react";
import './AdminZones.scss';
import {Button, TextField} from "@material-ui/core";
import {DataGrid, GridOverlay} from '@material-ui/data-grid';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {deleteZone, getAllZones} from "../../../../services/zone";
import {useParams} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import {setMessage, setOpen} from "../../../../reducers/snackbarReducer";
import {useDispatch} from "react-redux";
import {DeleteDialog} from "../DeleteDialog/DeleteDialog";
import ZoneDialogComponent from "./zoneDialogComponent/zoneDialogComponent";

export default function AdminZones() {

    /* Refresher function */

    function refreshPage() {
        window.location.reload();
    }

    const dispatch = useDispatch();

    let params = useParams();
    let cityId = params.id;

    /* Zone each menu */
    const [city, setCity] = useState(undefined);
    const [isOpenZone, setIsOpenZone] = useState(false);
    const [zones, setZones] = useState([]);
    const [showZoneDialog, setShowZoneDialog] = useState(false);
    const [anchorEls, setAnchorEls] = useState([]);
    const [countryId, setCountryId] = useState([]);
    const [dataLoading, isDataLoading] = useState(true);
    const [rowData, setRowData] = useState({});
    const [deletionDialogContent, setDeletionDialogContent] = useState("");
    const [submitFunction, setSubmitFunction] = useState(null);

    const handleClick = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = event.target;
        setAnchorEls(aels);
        //  setAnchorEl(event.currentTarget);
    };

    const handleClose = (id, event) => {
        // setAnchorEl(null);
        let aels = anchorEls.slice();
        aels[id] = null;
        setAnchorEls(aels);
    };

    /* Fetch all zones data */

    useEffect(()=>{
        getAllZones(cityId).then(({data})=> {
            let res = [];
            let zones = data[0].zones;
            console.log(data);
            zones.forEach((zone)=> {
                res.push({
                    id: zone.num_order,
                    id_original: zone.id,
                    zone: zone.name,
                    slug: zone.slug,
                    numOrder: zone.num_order,
                    showAction: ''
                });
            })
            setZones(res);
            setCity(data[0]);
            setCountryId(data[0].country_id);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
            isDataLoading(false);
        });
    },[])

    /* Open the zone delete dialog */

    const handleClickOpenDeleteZoneDialog = (data) => {
        const zoneDel = {
            "zone_id": data.id_original
        }
        setSubmitFunction(() => ()=>{
            deleteZone(zoneDel).then((data)=> {
                if (data.data.RESPONSECODE == 200) {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                    refreshPage();
                } else {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                }
            }).catch((e)=>{
                dispatch(setMessage({message : "Erreur lors de la suppression"}));
                dispatch(setOpen({open : true}));
                console.error(e);
            }).finally((e)=> {
                console.log(e);
            });
            handleClickCloseDeleteZoneDialog();
        });
        setDeletionDialogContent("Êtes vous sûr de vouloir supprimer le quartier " + data.zone)
        setIsOpenZone(true);
    };

    const handleClickCloseDeleteZoneDialog = () => {
        setIsOpenZone(false);
    };

    /* Update and add dialog */

    const openZoneDialog = (data) => {
        setRowData(data);
        setShowZoneDialog(true);
    }

    const closeZoneDialog = () => {
        setRowData({});
        setShowZoneDialog(false);
    }

    /* Zones data grid header infos */

    const columns = [
        /*{ field: 'id', headerName: 'ID', width: 70 },*/
        { field: 'numOrder', headerName: 'Numéro d\'ordre', width: 140 },
        { field: 'zone', headerName: 'Quartier', width: 250,},
        { field: 'slug', headerName: 'Slug', width: 120,},
        { field: 'showAction', headerName: 'Actions', width: 100,
            renderCell: (params) => (
                <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={e => handleClick(params.rowModel.id, e)}>
                        Actions
                    </Button>
                    <Menu
                        id={params.rowModel.id}
                        anchorEl={anchorEls[params.rowModel.id]}
                        keepMounted
                        open={Boolean(anchorEls[params.rowModel.id])}
                        onClose={e => handleClose(params.rowModel.id, e)}
                    >
                        <MenuItem onClick={(e) => {
                            handleClose(params.rowModel.id, e);
                            openZoneDialog(params.rowModel.data);
                        }}>
                            <CreateIcon/>Modifier</MenuItem>
                        <MenuItem onClick={(e) => {
                            handleClose(params.rowModel.id, e);
                            handleClickOpenDeleteZoneDialog(params.rowModel.data);
                        }}>
                            <DeleteIcon/>Supprimer
                        </MenuItem>

                    </Menu>
                </div>
            ),},
    ];

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                { dataLoading ? <div className="data-loading"> <CircularProgress /> Chargement en cours... </div> : <div>Pas de données</div> }
            </GridOverlay>
        );
    }

    return (
        <div className="admin-content-wrapper zones">
            <div className="admin-content-wrapper-title">
                <IconButton href={`/dashboard/countries/${countryId}/cities`}>
                    <ArrowBackIcon />
                </IconButton>
                <h2>Details ville</h2>
            </div>
            <div className="item-details">
                <div className="item-details-infos">
                    <ul>
                        <li>Ville :</li>
                        <li>{city ? city.name : ""}</li>
                    </ul>
                    <ul>
                        <li>Ordre :</li>
                        <li>{city ? city.num_order: ""}</li>
                    </ul>
                    <ul>
                        <li>Date de modification :</li>
                        <li>{city ? city.updated_at: ""}</li>
                    </ul>
                </div>
            </div>
            <div className="page-header">
                <div className="page-header-add">
                    <Button onClick={openZoneDialog}>Ajouter un quartier</Button>
                    <ZoneDialogComponent showDialog={showZoneDialog} zone={rowData} cityId={cityId} handleClose={closeZoneDialog} />
                </div>
                <div className="page-header-search">
                    <TextField
                        /*label="Recherche"*/
                        placeholder="Recherche"
                        type="text"
                        variant="outlined"
                        className="search-field"
                        name="search"
                        id="search"
                        InputProps={{
                            startAdornment: <SearchIcon/>
                        }}
                    />
                    <Button>Chercher</Button>
                </div>
            </div>
            <div className="countires-list">
                <div className="grid-wrapper" style={{ display: 'block', width: '100%' }}>
                    <DataGrid
                        rows={zones}
                        columns={columns}
                        pageSize={10}
                        autoHeight={true}
                        disableSelectionOnClick={true}
                        components={{
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                    />
                </div>
            </div>
            <DeleteDialog message={deletionDialogContent} isOpen={isOpenZone} submit={submitFunction} handleClose={handleClickCloseDeleteZoneDialog} />
        </div>
    );
}