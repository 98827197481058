import React from "react";
import IconButton from "@material-ui/core/IconButton";
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";

export const DeleteDialog = ({
                                submit,
                                 isOpen,
                                 handleClose,
                                 message
                             }) =>  {
    return (
        <div>
            <Dialog
                className="dialog small"
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <IconButton aria-label="close" className="closeButton" onClick={handleClose}>
                    <MdClose />
                </IconButton>
                <div className="change-container">
                    <DialogTitle id="dialog-title">{"Suppression d'un élement"}</DialogTitle>
                    <DialogContent>
                        <div className="dialog-container">
                            <p>{message}</p>
                        </div>
                    </DialogContent>
                    <DialogActions className="confirmation-button">
                        <Button onClick={handleClose} color="primary">
                            Annuler
                        </Button>
                        <Button color="primary" onClick={()=>submit()}>
                            Supprimer
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}