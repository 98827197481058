import React, {useEffect, useState} from "react";
import './AdminIcons.scss';
import {deleteIcon, getAllIcons} from "../../../../../services/icon";
import {Button, TextField} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import {DeleteDialog} from "../../DeleteDialog/DeleteDialog";
import {DataGrid, GridOverlay} from "@material-ui/data-grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import SearchIcon from "@material-ui/icons/Search";
import {setMessage, setOpen} from "../../../../../reducers/snackbarReducer";
import {useDispatch} from "react-redux";
import CreateIcon from "@material-ui/icons/Create";
import IconDialogComponent from "./iconDialogComponent/iconDialogComponent";
import {staticApiUrl} from '../../../../../utils/axios';

export default function AdminIcons() {

    function refreshPage() {
        window.location.reload();
    }

    const dispatch = useDispatch();

    /* Open the delete dialog */

    const [isOpenIcon, setIsOpenIcon] = useState(false);
    const [deletionIconDialogContent, setDeletionIconDialogContent] = useState("");
    const [submitIconFunction, setSubmitIconFunction] = useState(null);
    const [anchorEls, setAnchorEls] = useState([]);
    const [icons, setIcons] = useState([]);
    const [dataLoading, isDataLoading] = useState(true);
    const [showIconDialog, setShowIconDialog] = useState(false);
    const [rowDataIcons, setRowDataIcons] = useState({});

    /* Icon delete */
    const handleClickOpenDeleteIconDialog = (data) => {
        //console.log(data);
        const iconDel = {
            "icon_id": data.id
        }
        setSubmitIconFunction(() => ()=>{
            deleteIcon(iconDel).then((data)=> {
                if (data.data.RESPONSECODE == 200) {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                    refreshPage();
                } else {
                    dispatch(setMessage({message : data.data.RESPONSEMESSAGE}));
                    dispatch(setOpen({open : true}));
                }
            }).catch((e)=>{
                dispatch(setMessage({message : "Erreur lors de la suppression"}));
                dispatch(setOpen({open : true}));
                console.error(e);
            }).finally((e)=> {
                console.log(e);
            });
            handleClickCloseDeleteIconDialog();
        });
        setDeletionIconDialogContent("Êtes vous sûr de vouloir supprimer l'icône " + data.iconName)
        setIsOpenIcon(true);
    };

    const handleClickCloseDeleteIconDialog = () => {
        setIsOpenIcon(false);
    };

    /* Icon Each Menu view */
    const handleClickIcon = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = event.target;
        setAnchorEls(aels);
    };

    const handleCloseIcon = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = null;
        setAnchorEls(aels);
    };

    /* Update and add icon dialog */
    const openIconDialog = (data) => {
        //console.log(data);
        setRowDataIcons(data);
        setShowIconDialog(true);
    }

    const closeIconDialog = () => {
        setRowDataIcons({});
        setShowIconDialog(false);
    }

    /* Fetch all Icons data */
    useEffect(()=>{
        getAllIcons().then(({data})=> {
            //console.log(data);
            let res = [];
            let url = `${staticApiUrl}/iconimages/`;
            data.forEach((icon)=> {
                let filename = "no-image";
                if (icon.image[0] !== undefined) {
                    filename = icon.image[0].filename;
                }
                res.push({
                    id: icon.id,
                    iconName: icon.name,
                    iconImage: url + filename,
                    showAction: ''
                });
            })
            setIcons(res);
            //console.log(res);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
            isDataLoading(false);
        })
    },[]);

    /* Data Grid header infos */
    const columnsIcons = [
        /*{ field: 'id', headerName: 'ID', width: 70 },*/
        { field: 'iconName', headerName: 'Nom', width: 320 },
        { field: 'iconImage', headerName: 'icon', width: 120,
            renderCell: (params) => (
                <div>
                    <img style={{width: 30}} src={params.rowModel.data.iconImage} alt="icon"/>
                </div>
            ),
        },
        { field: 'showAction', headerName: 'Actions', width: 120,
            renderCell: (params) => (
                <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={e => handleClickIcon(params.rowModel.id, e)}>
                        Actions
                    </Button>
                    <Menu
                        id={params.rowModel.id}
                        anchorEl={anchorEls[params.rowModel.id]}
                        keepMounted
                        open={Boolean(anchorEls[params.rowModel.id])}
                        onClose={e => handleCloseIcon(params.rowModel.id, e)}
                    >
                        <MenuItem onClick={(e) => {
                            handleCloseIcon(params.rowModel.id, e);
                            openIconDialog(params.rowModel.data, e);
                        }}>
                            <CreateIcon/>Modifier
                        </MenuItem>
                        <MenuItem onClick={(e) => {
                            handleCloseIcon(params.rowModel.id, e);
                            handleClickOpenDeleteIconDialog(params.rowModel.data);
                        }}>
                            <DeleteIcon/>Supprimer
                        </MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    /* No data display */

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                { dataLoading ? <div className="data-loading"> <CircularProgress /> Chargement en cours... </div> : <div>Pas de données</div> }
            </GridOverlay>
        );
    };

    return (
        <div className="icons-wrapper">
            <h2>Ajout d'icone pour les catégories</h2>
            <div className="icons">
                <div className="page-header">
                    <div className="page-header-add">
                        <Button onClick={openIconDialog}>Ajouter une icone</Button>
                        <IconDialogComponent showDialog={showIconDialog} icone={rowDataIcons} handleCloseIcon={closeIconDialog} />
                    </div>
                    {/* Icons search form */}
                    <div className="page-header-search">
                        <TextField
                            /*label="Recherche"*/
                            placeholder="Recherche"
                            type="text"
                            variant="outlined"
                            className="search-field"
                            name="search"
                            id="search"
                            InputProps={{
                                startAdornment: <SearchIcon/>
                            }}
                        />
                        <Button>Chercher</Button>
                    </div>
                </div>
                {/* Icons data grid */}
                <div className="Icons-list">
                    <div className="icons-data-grid">
                        <DataGrid
                            rows={icons}
                            columns={columnsIcons}
                            pageSize={10}
                            autoHeight={true}
                            disableSelectionOnClick={true}
                            components={{
                                noRowsOverlay: CustomNoRowsOverlay,
                            }}
                        />
                    </div>
                </div>
            </div>
            <DeleteDialog message={deletionIconDialogContent} isOpen={isOpenIcon} submit={submitIconFunction} handleClose={handleClickCloseDeleteIconDialog} />
        </div>
    );
}