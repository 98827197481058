import axios from 'axios';

import {
    apiUrl,
} from '../utils/axios';
import {
    disconnectUser
} from "../reducers/userReducer";
import stores from '../store/index';

export function authenticateUser(username, password) {
    return axios.post(`${apiUrl}/admin_user_authen`, {username : username, password : password});
}

export function logUserOut() {
    stores.store.dispatch(disconnectUser());
}