import React, {useEffect} from "react";
import './AdminAdverts.scss';
import PropTypes from 'prop-types';
import {getAllPublishedAdverts, countAllPublishedAdverts, getAllSuspendedAdverts, getAllUnpublishedAdverts, validateAdvert, refuseAdvert} from "../../../../services/advert";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {Button, TextField} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import Link from "@material-ui/core/Link";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MenuItem from "@material-ui/core/MenuItem";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from '@mui/styles';
import { DataGrid, useGridApiContext, useGridState, GridOverlay } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useSelector} from "react-redux";
import ReactPaginate from 'react-paginate'

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
});

function TabPanel(props) {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function AdminAdverts() {

    let currentCountry = useSelector(state => state.country.currentCountry);
    const [value, setValue] = React.useState(0);
    const [publishedAdverts, setPublishedAdverts] = React.useState([]);
    const [unpublishedAdverts, setUnpublishedAdverts] = React.useState([]);
    const [suspendedAdverts, setSuspendedAdverts] = React.useState([]);
    const [dataLoading, isDataLoading] = React.useState(true);
    const [page, setPage] = React.useState(0);
    const [rowCount, setRowCount] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handlePageChange = (page) => {
        console.warn(page);
        setPage(page * 20);
        isDataLoading(true);
        handlePagination()
    };

    // ** Function to handle Pagination and get data
    const handlePagination = () => {

        const advertData = {
            offset: page,
            limit: 10,
            country_id:  currentCountry ? currentCountry.id : ''
        };
        getAllPublishedAdverts(advertData).then(({data})=> {
            let res = [];
            let ads = data.RESPONSEDATA;
            console.warn(ads);
            ads.forEach((advert)=> {
                res.push({
                    id: advert.advert_id,
                    member: advert.first_name != null ? advert.first_name : '-',
                    title: advert.title != "" ? advert.title : '-',
                    city: advert.city_name != null ? advert.city_name : '-',
                    date: advert.created_at != null ? advert.created_at : '-',
                    showAction: ''
                });
            })
            setPublishedAdverts(res);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
            isDataLoading(false);
        });
    }

    const CustomPagination = () => {
        const apiRef = useGridApiContext();
        const [state] = useGridState(apiRef);
        const classes = useStyles();
    
        return (
            <Pagination
                className={classes.root}
                color="primary"
                count={state.pagination.pageCount}
                page={state.pagination.page + 1}
                onChange={(event, value) => {apiRef.current.setPage(value - 1); handlePageChange(value)}}
            />
        );
    }
    useEffect(()=>{
        const advertData = {
            offset: page,
            limit: 10,
            country_id:  currentCountry ? currentCountry.id : ''
        };
        getAllPublishedAdverts(advertData).then(({data})=> {
            let res = [];
            let ads = data.RESPONSEDATA;
            console.warn(ads);
            ads.forEach((advert)=> {
                res.push({
                    id: advert.advert_id,
                    member: advert.first_name != null ? advert.first_name : '-',
                    title: advert.title != "" ? advert.title : '-',
                    city: advert.city_name != null ? advert.city_name : '-',
                    date: advert.created_at != null ? advert.created_at : '-',
                    showAction: ''
                });
            })
            setPublishedAdverts(res);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
            isDataLoading(false);
        });

        countAllPublishedAdverts(advertData).then(({data})=> {
            setRowCount(data.total_adverts);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
            isDataLoading(false);
        });

        /* Unpublished Ads */
        getAllUnpublishedAdverts(advertData).then(({data})=> {
            let res = [];
            let ads = data.RESPONSEDATA;
            console.warn(ads);

            ads.forEach((advert)=> {
                res.push({
                    id: advert.advert_id,
                    member: advert.first_name,
                    title: advert.title,
                    city: advert.city_name,
                    date: advert.created_at,
                    showAction: ''
                });
            })
            setUnpublishedAdverts(res);
            
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
        });

        //setSuspendedAdverts([]);
        /*Suspended Ads*/
        getAllSuspendedAdverts(advertData).then(({data})=> {
            let res = [];
            let ads = data.RESPONSEDATA;
            /*
            console.log(ads);
            ads.forEach((advert)=> {
                res.push({
                    id: advert.advert_id,
                    member: advert.first_name,
                    title: advert.title,
                    city: advert.city_name,
                    date: advert.created_at,
                    showAction: ''
                });
            })
            setSuspendedAdverts(res); */
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
        })
    },[setPublishedAdverts]);

    /* Advert Each Menu view */

    const [anchorEls, setAnchorEls] = React.useState([]);

    /* Actions constantes declared */

    const handleClick = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = event.target;
        setAnchorEls(aels);
    };

    const handleClose = (id, event) => {
        let aels = anchorEls.slice();
        aels[id] = null;
        setAnchorEls(aels);
    };

    /* Data Grid header infos */

    const columns = [
        { field: 'id', headerName: 'ID', width: 120 },
        { field: 'member', headerName: 'Membre', width: 120 },
        { field: 'title', headerName: 'Titre', width: 250 },
        { field: 'city', headerName: 'Ville', width: 120 },
        { field: 'date', headerName: 'Date', width: 200 },
        { field: 'showAction', headerName: 'Actions', width: 100,
            renderCell: (params) => (
                <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={e => handleClick(params.id, e)}>
                        Actions
                    </Button>
                    <Menu
                        id={params.id}
                        anchorEl={anchorEls[params.id]}
                        keepMounted
                        open={Boolean(anchorEls[params.id])}
                        onClose={e => handleClose(params.id, e)}
                    >
                        <Link href={`/dashboard/adverts/${params.id}/ad`} onClick={e => handleClose(params.id, e)}><VisibilityIcon/>Voir</Link>
                        <MenuItem onClick={(e) => {
                            handleClose(params.id, e);
                        }}>
                            <DeleteIcon/>Supprimer
                        </MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    const unPublishedcolumns = [
        { field: 'id', headerName: 'ID', width: 120 },
        { field: 'member', headerName: 'Membre', width: 120 },
        { field: 'title', headerName: 'Titre', width: 250 },
        { field: 'city', headerName: 'Ville', width: 120 },
        { field: 'date', headerName: 'Date', width: 200 },
        { field: 'showAction', headerName: 'Actions', width: 100,
            renderCell: (params) => (
                <div>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={e => handleClick(params.id, e)}>
                        Actions
                    </Button>
                    <Menu
                        id={params.id}
                        anchorEl={anchorEls[params.id]}
                        keepMounted
                        open={Boolean(anchorEls[params.id])}
                        onClose={e => handleClose(params.id, e)}
                    >
                        <MenuItem onClick={(e) => {
                            handleClose(params.id, e);
                            validateAd(params.id)
                        }}>
                            <DoneIcon/>Valider
                        </MenuItem>
                        <MenuItem onClick={(e) => {
                            handleClose(params.id, e);
                            RefuseAd(params.id)
                        }}>
                            <CloseIcon/>Refuser
                        </MenuItem>
                    </Menu>
                </div>
            ),
        },
    ];

    /* No data display */

    function CustomNoRowsOverlay() {
        return (
            <GridOverlay>
                { dataLoading ? <div className="data-loading"> <CircularProgress /> Chargement en cours... </div> : <div>Pas de données</div> }
            </GridOverlay>
        );
    };

    // ** Function to handle Pagination and get data
    // const handlePagination = (page) => {
    //     setPage(page.selected + 1)

    // }

    const validateAd = (advert_id) => {
        const data = {
            advert_id: advert_id
        }
        validateAdvert(data).then(result => {
        }).catch(error => {
            console.warn(error);
        });
    }

    const RefuseAd = (advert_id) => {
        const data = {
            advert_id: advert_id
        }
        refuseAdvert(data).then(result => {
            window.location.reload();
        }).catch(error => {
            console.warn(error);
        });
    }

    return (
        <div className="adverts-content">
            <h1>Annonces</h1>
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                <Tab label="En ligne" {...a11yProps(0)} />
                <Tab label="En attente" {...a11yProps(1)} />
                <Tab label="Refusés" {...a11yProps(2)} />
                {/*<Tab label="Expirés" {...a11yProps(3)} />*/}
            </Tabs>
            <TabPanel value={value} index={0}>
                <div className="page-header">
                    <div className="page-header-search">
                        <TextField
                            /*label="Recherche"*/
                            placeholder="Recherche"
                            type="text"
                            variant="outlined"
                            className="search-field"
                            name="search"
                            id="search"
                            InputProps={{
                                startAdornment: <SearchIcon/>
                            }}
                        />
                        <Button>Chercher</Button>
                    </div>
                </div>
                <div className="grid-wrapper" style={{}}>
                    <DataGrid
                        rows={publishedAdverts}
                        columns={columns}
                        pagination
                        paginationMode="server"
                        pageSize={10}
                        components={{
                            noRowsOverlay: CustomNoRowsOverlay,
                            Pagination: CustomPagination,
                        }}
                        disableColumnMenu
                        rowCount={rowCount}
                        loading={dataLoading}
                    />
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div className="page-header">
                    <div className="page-header-search">
                        <TextField
                            /*label="Recherche"*/
                            placeholder="Recherche"
                            type="text"
                            variant="outlined"
                            className="search-field"
                            name="search"
                            id="search"
                            InputProps={{
                                startAdornment: <SearchIcon/>
                            }}
                        />
                        <Button>Chercher</Button>
                    </div>
                </div>
                <div className="grid-wrapper" style={{}}>
                    <DataGrid
                        rows={unpublishedAdverts}
                        columns={unPublishedcolumns}
                        pagination
                        paginationMode="server"
                        pageSize={10}
                        components={{
                            noRowsOverlay: CustomNoRowsOverlay,
                            Pagination: CustomPagination,
                        }}
                        disableColumnMenu
                        rowCount={rowCount}
                        loading={dataLoading}
                    />
                </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <div className="page-header">
                    <div className="page-header-search">
                        <TextField
                            /*label="Recherche"*/
                            placeholder="Recherche"
                            type="text"
                            variant="outlined"
                            className="search-field"
                            name="search"
                            id="search"
                            InputProps={{
                                startAdornment: <SearchIcon/>
                            }}
                        />
                        <Button>Chercher</Button>
                    </div>
                </div>
                <div className="grid-wrapper" style={{  }}>
                    <DataGrid
                        rows={suspendedAdverts}
                        columns={columns}
                        pageSize={10}
                        autoHeight={true}
                        disableSelectionOnClick={true}
                        components={{
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                    />
                </div>
            </TabPanel>
            {/*<TabPanel value={value} index={3}>
                <div className="page-header">
                    <div className="page-header-search">
                        <TextField
                placeholder="Recherche"
                type="text"
                variant="outlined"
                className="search-field"
                name="search"
                id="search"
                InputProps={{
                startAdornment: <SearchIcon/>
            }}
                />
                <Button>Chercher</Button>
                </div>
                </div>
                </TabPanel>*/}
        </div>
    );
}
