import React, {useEffect} from "react";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import fr from 'react-phone-input-2/lang/fr.json';
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Button, TextField} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import {useFormik} from "formik";
import * as Yup from "yup";
import {setMessage, setOpen} from "../../../../reducers/snackbarReducer";
import {useDispatch} from "react-redux";
import {getAllCountries} from "../../../../services/country";
import {createAdminUser, getUserTypes, updateAdminUser} from "../../../../services/admin";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";

export default function AdminDialogComponent ({showDialog, admin, handleClose}) {

    /* Refresher function */

    //console.log(admin);

    function refreshPage() {
        window.location.reload();
    }

    const dispatch = useDispatch();

    /* Add or update a new admin */

    const [countries, setCountries] = React.useState([]);
    const [userTypes, setUserTypes] = React.useState([]);

    /* Get All countries and types */

    useEffect(()=> {
        getAllCountries().then(({data})=> {
            //console.log(data);
            setCountries(data);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
        });

        getUserTypes().then(({data}) => {
            //console.log(data);
            setUserTypes(data);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
        })
    },[]);

    const formik = useFormik({
        initialValues: {
            username: '',
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            indicatif: '',
            password: '',
            type: '',
            permissions: null,
            country_permission: '',
            validated: 1,
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .required('Ce champ est requis'),
            first_name: Yup.string()
                .required('Ce champ est requis'),
            last_name: Yup.string()
                .required('Ce champ est requis'),
            email: Yup.string()
                .email('Adresse Email invalide')
                .required('Ce champ est requis'),
            phone: Yup.string()
                .min(8, "Au min 8 chiffres").required("Ce champ est requis"),
            password: Yup.lazy(() => {
                return admin.id ?
                    Yup.string() :
                    Yup.string()
                        .required('Ce champ est requis')
            }),
            type: Yup.string()
                .required('Ce champ est requis'),
            country_permission: Yup.string()
                .required('Ce champ est requis'),
        }),
        onSubmit: (values, actions) => {

            let adminUser = {...values};

            let p = values.indicatif.length;
            adminUser.phone = adminUser.phone.substring(p - 0);

            adminUser.validated = adminUser.validated == false ? 0 : 1;

            for (let i = 0; i < adminUser.country_permission.length; i++) {
                adminUser.country_permission[i] = +adminUser.country_permission[i];
            }

            adminUser.country_permission = "[" + adminUser.country_permission + "]";

            //console.log(JSON.stringify(adminUser, null, 2));

            if (admin.id) {
                console.log(JSON.stringify(adminUser, null, 2));
                /*updateAdminUser(adminUser).then(({data})=> {
                    if (data.RESPONSECODE === 200) {
                        dispatch(setMessage({message : data.RESPONSEMESSAGE}));
                        dispatch(setOpen({open : true}));
                        //console.log(data);
                        refreshPage()
                    } else {
                        dispatch(setMessage({message : data.RESPONSEMESSAGE}));
                        dispatch(setOpen({open : true}));
                    }
                }).catch((e)=>{
                    dispatch(setMessage({message : "Erreur lors de la création"}));
                    dispatch(setOpen({open : true}));
                    console.error(e);
                }).finally((e)=>{
                    console.log(e);
                    actions.setSubmitting(false);
                })*/
            } else {
                //console.log(adminUser);
                createAdminUser(adminUser).then(({data})=> {
                    if (data.RESPONSECODE === 200) {
                        dispatch(setMessage({message : data.RESPONSEMESSAGE}));
                        dispatch(setOpen({open : true}));
                        //console.log(data);
                        refreshPage()
                    } else {
                        dispatch(setMessage({message : data.RESPONSEMESSAGE}));
                        dispatch(setOpen({open : true}));
                    }
                }).catch((e)=>{
                    dispatch(setMessage({message : "Erreur lors de la création"}));
                    dispatch(setOpen({open : true}));
                    console.error(e);
                }).finally((e)=>{
                    console.log(e);
                    actions.setSubmitting(false);
                })
            }
        },
    });

    useEffect(()=>{
        if(admin.id) {
            formik.values.username = admin.username;
            formik.values.last_name = admin.lastName;
            formik.values.first_name = admin.firstName;
            formik.values.email = admin.email;
            formik.values.phone = admin.fullphone;
            formik.values.indicatif = admin.indicatif;
            formik.values.type = admin.userType;
            formik.values.country_permission = admin.countriesId;
            formik.values.password = admin.password;
            formik.values.user_id = admin.id
        } else {
            formik.values.username = '';
            formik.values.last_name = '';
            formik.values.first_name = '';
            formik.values.email = '';
            formik.values.phone = '';
            formik.values.indicatif = '';
            formik.values.type = '';
            formik.values.country_permission = '';
            formik.values.password = '';
        }
    }, [admin]);

    const togglePassword = ({ currentTarget }) => {
        currentTarget.classList.toggle('active');
        const passwordInput = document.getElementById('password');

        if (currentTarget.classList.contains('active')) {
            passwordInput.type = 'text';
        }
        else {
            passwordInput.type = 'password';
        }
    }

    return (
        <div className="city-dialog">
            <Dialog
                className="dialog large"
                open={showDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <IconButton aria-label="close" className="closeButton" onClick={handleClose}>
                    <MdClose />
                </IconButton>
                <div className="change-container custom-modal">
                    <DialogTitle id="dialog-title">{ admin.id ? "Modification d'un administrateur" : "Création d'un administrateur" }</DialogTitle>
                    <DialogContent>
                        <div className="dialog-container">
                            <form onSubmit={formik.handleSubmit}>
                                <div className="full-form-wrapper">
                                    <TextField
                                        name="username"
                                        label="identifiant"
                                        type="text"
                                        variant="outlined"
                                        className={"name-field" + (formik.errors.username && formik.touched.username ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={admin.username}
                                    />
                                    {formik.touched.username && formik.errors.username ? (
                                        <p className="errorMessage">{formik.errors.username}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper">
                                    <TextField
                                        name="last_name"
                                        label="Nom"
                                        type="text"
                                        variant="outlined"
                                        className={"lastName-field" + (formik.errors.last_name && formik.touched.last_name ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={admin.lastName}
                                    />
                                    {formik.touched.last_name && formik.errors.last_name ? (
                                        <p className="errorMessage">{formik.errors.last_name}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper">
                                    <TextField
                                        name="first_name"
                                        label="Prénom"
                                        type="text"
                                        variant="outlined"
                                        className={"firstName-field" + (formik.errors.first_name && formik.touched.first_name ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={admin.firstName}
                                    />
                                    {formik.touched.first_name && formik.errors.first_name ? (
                                        <p className="errorMessage">{formik.errors.first_name}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper">
                                    <TextField
                                        name="email"
                                        label="Email"
                                        type="text"
                                        variant="outlined"
                                        className={"email-field" + (formik.errors.email && formik.touched.email ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={admin.email}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <p className="errorMessage">{formik.errors.email}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper tel-input">
                                    <PhoneInput
                                        inputProps={{id : "phoneNumber", name : "phone"}}
                                        onChange={(phone, data) => {
                                            formik.setFieldValue('phone', phone);
                                            formik.setFieldValue('indicatif', "" + data.dialCode);
                                        }}
                                        onBlur={formik.handleBlur}
                                        /*value={formik.values.login}*/
                                        value={admin.phone}
                                        localization={fr}
                                        country={'tg'}
                                        searchPlaceholder={'Rechercher'}
                                        countryCodeEditable={false}
                                        containerClass={'phone-input' + (formik.errors.phone && formik.touched.phone ? ' is-invalid' : '')}
                                        placeholder={'Numéro de téléphone'}
                                        defaultMask={'.. .. .. .. .. ..'}
                                        preferredCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}
                                        /*onlyCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}*/
                                        enableSearch={true}
                                    />
                                    {formik.touched.phone && formik.errors.phone ? (
                                        <p className="errorMessage">{formik.errors.phone}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper">
                                    <TextField
                                        name="password"
                                        label="Mot de passe"
                                        type="password"
                                        variant="outlined"
                                        id="password"
                                        className={"name-field" + (formik.errors.password && formik.touched.password ? ' is-invalid' : '')}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        defaultValue={admin.password}
                                    />
                                    <span className="show-password" onClick={togglePassword}><VisibilityIcon /></span>
                                    {formik.touched.password && formik.errors.password ? (
                                        <p className="errorMessage">{formik.errors.password}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper">
                                    <FormControl
                                        className={"select-role" + (formik.errors.type && formik.touched.type ? ' is-invalid' : '')}
                                    >
                                        <InputLabel id="role-label">Selectionner un rôle</InputLabel>
                                        <Select
                                            name="type"
                                            id="role"
                                            labelId="role-label"
                                            variant="outlined"
                                            size="small"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            defaultValue={admin.userType}
                                        >
                                            {
                                                userTypes.map((userType) => (
                                                        <MenuItem key={userType.id} value={userType.id}>{userType.name_fr}</MenuItem>
                                                    )
                                                )
                                            }
                                        </Select>
                                    </FormControl>
                                    {formik.touched.type && formik.errors.type ? (
                                        <p className="errorMessage">{formik.errors.type}</p>
                                    ) : null}
                                </div>
                                <div className="full-form-wrapper checkbox-group">
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Selectionner un pays</FormLabel>
                                        <FormGroup aria-label="position" row>
                                            {
                                                countries.map((country) => (
                                                        <FormControlLabel
                                                            key={country.id}
                                                            value={country.id}
                                                            //control={<Checkbox  />}
                                                            control={<Checkbox defaultChecked={admin.id ? (admin.countries.find(c=> c.id == country.id) ? true : false) : ('')} />}
                                                            label={country.name}
                                                            labelPlacement="end"
                                                            name="country_permission"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                        />
                                                    )
                                                )
                                            }
                                        </FormGroup>
                                    </FormControl>
                                </div>
                                <div className="full-form-wrapper published">
                                    <FormControlLabel
                                        control={
                                            <div>
                                                <Checkbox
                                                    name="validated"
                                                    color="secondary"
                                                    id="validated"
                                                    defaultChecked={true}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    value={formik.values.published}
                                                />
                                            </div>
                                        }
                                        label={<label htmlFor="published">Activé</label>} />
                                </div>
                            </form>
                        </div>
                    </DialogContent>
                    <DialogActions className="confirmation-button">
                        <Button onClick={() => {
                            formik.resetForm()
                            handleClose();
                        }} color="primary">
                            Annuler
                        </Button>
                        <Button type="submit" onClick={formik.handleSubmit} disabled={formik.isSubmitting} color="primary">
                            {admin.id ? "Modifier" : "Créer" }
                            {
                                formik.isSubmitting ?
                                    (<CircularProgress />) : ('')
                            }
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}