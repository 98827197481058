import React, {useEffect, useState} from "react";
import './adminMembersDetails.scss';
import fr from 'react-phone-input-2/lang/fr.json';
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Button from "@material-ui/core/Button";
import user from '../../../../../imgs/userBlack.svg';
import { DeleteDialog } from "../../DeleteDialog/DeleteDialog";
import {createCountry, deleteCountry, getCountryWithCities} from "../../../../../services/country";
import {setMessage, setOpen} from "../../../../../reducers/snackbarReducer";
import {useDispatch} from "react-redux";
import {MdClose} from "react-icons/md";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {TextField} from "@material-ui/core";
import Icon from "@iconify/react";
import cameraPlusOutline from "@iconify/icons-mdi/camera-plus-outline";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import {useFormik} from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import {useParams} from "react-router-dom";
import {getMember} from "../../../../../services/member";

export default function AdminMembersDetails() {

    // On définit de manière dynamique le placeholder des inputs
    // des téléphones (la lib utilisée ne le permet pas...)
    setTimeout(() => {
        const phoneInputContainers = document.querySelectorAll(':scope .react-tel-input');
        phoneInputContainers.forEach((container) => {
            const phoneInput = container.querySelector('input');
            const { placeholder } = phoneInput;
            const inputLabel = container.querySelector('.flag-dropdown');
            inputLabel.dataset.placeholder = placeholder;
        });
    });

    let params = useParams();
    let memberID = params.id;

    /*const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [deletionDialogContent, setDeletionDialogContent] = useState("");
    const [submitFunction, setSubmitFunction] = useState(null);*/

    /*const handleClickOpenDeleteDialog = (data, event) => {
        setSubmitFunction(() => ()=>{
            deleteUser({
                user_id: data.id
            }).then(()=> {
                dispatch(setMessage({message : "Membre supprimé avec succès"}));
                dispatch(setOpen({open : true}));
            }).catch((e)=>{
                dispatch(setMessage({message : "Erreur lors de la suppression"}));
                dispatch(setOpen({open : true}));
                console.error(e);
            }).finally((e)=> {
                console.log(e);
            });
            handleClickCloseDeleteDialog();
        });
        setDeletionDialogContent("Etes-vous sur de vouloir supprimer l'utilisateur " + data.user)
        setIsOpen(true);
    };*/

    /*const handleClickCloseDeleteDialog = (id, event) => {
        setIsOpen(false);
    };*/

    /* Update user modal */

    const [updateUserForm, setUpdateUserForm] = React.useState(false);

    const handleOpenUpdateUserForm = () => {
        setUpdateUserForm(true);
    };

    const handleCloseUpdateUserForm = () => {
        setUpdateUserForm(false);
    };

    /* Update user Form submit */

    const formik = useFormik({
        initialValues: {
            firstName: undefined,
            lastName: undefined,
            email: undefined,
            address: undefined,
            phone: undefined,
            whatsapp: undefined,
            rs: undefined,
            rccm: undefined,
            category_id: undefined,
            activity: undefined,
            description: undefined,
            hours: undefined,
            website: undefined
        },
        validationSchema: Yup.object({
            firstName: Yup.string()
                .required('Ce champ est requis'),
            lastName: Yup.string()
                .required('Ce champ est requis'),
            email: Yup.string()
                .email('Adresse Email invalide')
                .required('Ce champ est requis'),
            address: Yup.string()
                .required('Ce champ est requis'),
            phone: Yup.string()
                .min(8, "Au min 8 caractères").required("Ce champ est requis"),
            whatsapp: Yup.string()
                .min(8, "Au min 8 caractères").required("Ce Champ est requis"),
            rs: Yup.string()
                .required('Ce champ est requis'),
            rccm: Yup.string()
                .required('Ce champ est requis'),
            category_id: Yup.string()
                .required('Ce champ est requis'),
            activity: Yup.string()
                .required('Ce champ est requis'),
            description: Yup.string()
                .required('Ce champ est requis'),
            hours: Yup.string()
                .required('Ce champ est requis'),
            website: Yup.string()
                .required('Ce champ est requis'),
        }),
        /*onSubmit: (values, actions) => {
            updateUser(userData).then(({data})=>{
                dispatch(setMessage({message : "Utilisateur mis à jour avec succès"}));
                dispatch(setOpen({open : true}));
                //console.log(data);
            }).catch((e)=>{
                dispatch(setMessage({message : "Erreur lors de la création"}));
                dispatch(setOpen({open : true}));
                console.error(e);
            }).finally((e)=>{
                console.log(e);
                actions.setSubmitting(false);
                handleCloseUpdateUserForm();
            })
        },*/
    });

    /* get member infos */

    const [member, setMember] = React.useState(false);

    useEffect(()=>{
        const memberInfos = {
            "user_id": memberID
        }
        getMember(memberInfos).then(({data})=> {
            //console.log(data);
            setMember(data.RESPONSEDATA[0]);
        }).catch((e)=>{
            console.error(e);
        }).finally((e)=> {
            console.log(e);
        });
    },[])

    return (
        <div className="members-details">
            <div className="admin-content-wrapper-title">
                <div className="admin-content-wrapper-title-group">
                    <IconButton href={`/dashboard/members`}>
                        <ArrowBackIcon />
                    </IconButton>
                    <h2>Détails Utilisateur</h2>
                </div>
                <div className="admin-content-wrapper-title-buttons">
                    <Button onClick={handleOpenUpdateUserForm}>Modifier</Button>
                    <Dialog
                        className="dialog large"
                        open={updateUserForm}
                        onClose={handleCloseUpdateUserForm}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <IconButton aria-label="close" className="closeButton" onClick={handleCloseUpdateUserForm}>
                            <MdClose />
                        </IconButton>
                        <div className="change-container custom-modal">
                            <DialogTitle id="dialog-title">{"Modification de l'utilisateur"}</DialogTitle>
                            <DialogContent>
                                <div className="dialog-container">
                                    <form >
                                        <div className="half-form-wrapper">
                                            <TextField
                                                name="firstName"
                                                label="Nom"
                                                type="text"
                                                variant="outlined"
                                                className={"firstName" + (formik.errors.firstName && formik.touched.firstName ? ' is-invalid' : '')}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.firstName && formik.errors.firstName ? (
                                                <p className="errorMessage">{formik.errors.firstName}</p>
                                            ) : null}
                                        </div>
                                        <div className="half-form-wrapper">
                                            <TextField
                                                name="lastName"
                                                label="Nom"
                                                type="text"
                                                variant="outlined"
                                                className={"lastName-field" + (formik.errors.lastName && formik.touched.lastName ? ' is-invalid' : '')}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.lastName && formik.errors.lastName ? (
                                                <p className="errorMessage">{formik.errors.lastName}</p>
                                            ) : null}
                                        </div>
                                        <div className="half-form-wrapper">
                                            <TextField
                                                name="email"
                                                label="Email"
                                                type="email"
                                                variant="outlined"
                                                className={"email-field" + (formik.errors.email && formik.touched.email ? ' is-invalid' : '')}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <p className="errorMessage">{formik.errors.email}</p>
                                            ) : null}
                                        </div>
                                        <div className="half-form-wrapper">
                                            <TextField
                                                name="address"
                                                label="Adresse"
                                                type="text"
                                                variant="outlined"
                                                className={"address-field" + (formik.errors.address && formik.touched.address ? ' is-invalid' : '')}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.address && formik.errors.address ? (
                                                <p className="errorMessage">{formik.errors.address}</p>
                                            ) : null}
                                        </div>
                                        <div className="half-form-wrapper tel-input">
                                            <PhoneInput
                                                inputProps={{id : "phoneNumber", name : "phone"}}
                                                onChange={(phone) => {
                                                    formik.setFieldValue('phone', phone);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.phone}
                                                localization={fr}
                                                country={'tg'}
                                                searchPlaceholder={'Rechercher'}
                                                countryCodeEditable={false}
                                                containerClass={'phone-input' + (formik.touched.phone && formik.errors.phone ? ' is-invalid' : '')}
                                                placeholder={'Numéro de téléphone'}
                                                defaultMask={'.. .. .. .. .. ..'}
                                                preferredCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}
                                                /*onlyCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}*/
                                                enableSearch={true}
                                            />
                                            {formik.touched.phone && formik.errors.phone ? (
                                                <p className="errorMessage">{formik.errors.phone}</p>
                                            ) : null}
                                        </div>
                                        <div className="half-form-wrapper tel-input">
                                            <PhoneInput
                                                inputProps={{id : "whatsappPhoneNumber", name : "whatsapp"}}
                                                onChange={(phone) => {
                                                    formik.setFieldValue('whatsapp', phone);
                                                }}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.whatsapp}
                                                localization={fr}
                                                country={'tg'}
                                                searchPlaceholder={'Rechercher'}
                                                countryCodeEditable={false}
                                                containerClass={'whatsapp-input' + (formik.errors.whatsapp && formik.touched.whatsapp ? ' is-invalid' : '')}
                                                placeholder={'Numéro Whatsapp'}
                                                defaultMask={'.. .. .. .. .. ..'}
                                                preferredCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}
                                                /*onlyCountries={['tg', 'cd', 'ml', 'sn', 'bj', 'ne', 'bf', 'ci']}*/
                                                enableSearch={true}
                                            />
                                            {formik.touched.whatsapp && formik.errors.whatsapp ? (
                                                <p className="errorMessage">{formik.errors.whatsapp}</p>
                                            ) : null}
                                        </div>
                                        <div className="full-form-wrapper">
                                            <TextField
                                                name="description"
                                                label="Description"
                                                type="text"
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                className={"description-field" + (formik.errors.description && formik.touched.description ? ' is-invalid' : '')}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.description && formik.errors.description ? (
                                                <p className="errorMessage">{formik.errors.description}</p>
                                            ) : null}
                                        </div>
                                        <div className="full-form-wrapper">
                                            <FormControl className={"select-category" + (formik.errors.category_id && formik.touched.category_id ? ' is-invalid' : '')}>
                                                <InputLabel id="category-label">Catégorie de votre activité</InputLabel>
                                                <Select
                                                    name="category_id"
                                                    id="activityCategory"
                                                    labelId="category-label"
                                                    variant="outlined"
                                                    size="small"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.category_id}
                                                >
                                                    <MenuItem value="1">Informatique et multimédia</MenuItem>
                                                    <MenuItem value="2">Services</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {formik.touched.category_id && formik.errors.category_id ? (
                                                <p className="errorMessage">{formik.errors.category_id}</p>
                                            ) : null}
                                        </div>
                                        <div className="half-form-wrapper">
                                            <TextField
                                                name="rs"
                                                label="Raison sociale"
                                                type="text"
                                                variant="outlined"
                                                className={"rs-field" + (formik.errors.rs && formik.touched.rs ? ' is-invalid' : '')}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.rs && formik.errors.rs ? (
                                                <p className="errorMessage">{formik.errors.rs}</p>
                                            ) : null}
                                        </div>
                                        <div className="half-form-wrapper">
                                            <TextField
                                                name="rs"
                                                label="Numéro RCCM"
                                                type="text"
                                                variant="outlined"
                                                className={"rccm-field" + (formik.errors.rccm && formik.touched.rccm ? ' is-invalid' : '')}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.rccm && formik.errors.rccm ? (
                                                <p className="errorMessage">{formik.errors.rccm}</p>
                                            ) : null}
                                        </div>
                                        <div className="half-form-wrapper">
                                            <TextField
                                                name="hours"
                                                label="Horaires"
                                                type="text"
                                                variant="outlined"
                                                className={"hours-field" + (formik.errors.hours && formik.touched.hours ? ' is-invalid' : '')}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.hours && formik.errors.hours ? (
                                                <p className="errorMessage">{formik.errors.hours}</p>
                                            ) : null}
                                        </div>
                                        <div className="half-form-wrapper">
                                            <TextField
                                                name="website"
                                                label="Site web"
                                                type="text"
                                                variant="outlined"
                                                className={"website-field" + (formik.errors.website && formik.touched.website ? ' is-invalid' : '')}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.website && formik.errors.website ? (
                                                <p className="errorMessage">{formik.errors.website}</p>
                                            ) : null}
                                        </div>
                                    </form>
                                </div>
                            </DialogContent>
                            <DialogActions className="confirmation-button">
                                <Button onClick={handleCloseUpdateUserForm} color="primary">
                                    Annuler
                                </Button>
                                <Button type="submit" onClick={formik.handleSubmit} disabled={formik.isSubmitting} color="primary">
                                    Modifier
                                    {
                                        formik.isSubmitting ?
                                            (<CircularProgress />) : ('')
                                    }
                                </Button>
                            </DialogActions>
                        </div>
                    </Dialog>
                    <Button>Supprimer</Button>
                    {/*<Button onClick={handleClickOpenDeleteDialog(params.rowModel.data, e)}>Supprimer</Button>
                <DeleteDialog message={deletionDialogContent} isOpen={isOpen} submit={submitFunction} handleClose={handleClickCloseDeleteDialog} />*/}
                </div>
            </div>
            <div className="members-details-content">
                <div className="members-details-content-image">
                    <img src={user} alt="user"/>
                </div>
                <div className="members-details-content-infos">
                    <ul className="members-details-content-infos-item">
                        <li>ID : </li>
                        <li>{member ? member.id : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Nom : </li>
                        <li>{member ? member.last_name : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Prénoms : </li>
                        <li>{member ? member.first_name : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Email : </li>
                        <li>{member ? member.email : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Adresse : </li>
                        <li>{member ? member.address : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Pays : </li>
                        <li>{member ? member.country_name : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Ville : </li>
                        <li>{member ? member.city_name : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Quartier : </li>
                        <li>{member ? member.zone_name : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Vous êtes : </li>
                        <li>{member ? (member.type == 1 ? 'Particulier' : 'Professionnel') : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Numéro de téléphone : </li>
                        <li>{member ? ((member.phone_indicatif != null ? "+" + member.phone_indicatif : "") + (member.phone != null ? member.phone : "")) : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Numéro whatsapp : </li>
                        <li>{member ? ((member.whatsapp_indicatif != null ? "+" + member.whatsapp_indicatif : "") + (member.whatsapp != null ? member.whatsapp : "")) : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Raison sociale : </li>
                        <li>{member ? member.pro_rs : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Numéro RCCM : </li>
                        <li>{member ? member.pro_rc : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Catégorie : </li>
                        <li>{member ? member.category_name : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Titre de votre activité : </li>
                        <li>{member ? member.activity_title : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Description : </li>
                        <li>{member ? member.activity_description : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Horaires : </li>
                        <li>{member ? member.horaires : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Site internet : </li>
                        <li><a href={member ? member.website : ""} target="_blank">{member ? member.website : ""}</a></li>
                    </ul>
                    {/*<ul className="members-details-content-infos-item">
                        <li>Newsletter : </li>
                        <li>Non</li>
                    </ul>*/}
                    <ul className="members-details-content-infos-item">
                        <li>Validé : </li>
                        <li>{member ? (member.phone_validated == 1 ? 'Oui' : 'Non') : ""}</li>
                    </ul>
                    {/*<ul className="members-details-content-infos-item">
                        <li>Adresse Email validée : </li>
                        <li>Non</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Dernière connexion : </li>
                        <li>11/12/2030 23:23:10</li>
                    </ul>*/}
                    <ul className="members-details-content-infos-item">
                        <li>Boutique : </li>
                        <li>{member ? (member.type == 1 ? 'Non' : 'Oui') : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Date de création : </li>
                        <li>{member ? member.created_at : ""}</li>
                    </ul>
                    <ul className="members-details-content-infos-item">
                        <li>Date de modification : </li>
                        <li>{member ? member.updated_at : ""}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}