import axios from 'axios';
import {
    apiUrl,
} from '../utils/axios';

/* Online adverts */
export function getAllPublishedAdverts(publishedData) {
    return axios.post(`${apiUrl}/admin_get_published_adverts`, publishedData);
}

/* Count online adverts */
export function countAllPublishedAdverts(publishedData) {
    return axios.post(`${apiUrl}/count_adverts`, publishedData);
}

/* Unpublished adverts */
export function getAllUnpublishedAdverts(unpublishedData) {
    return axios.post(`${apiUrl}/admin_get_unpublished_adverts`, unpublishedData);
}

/* Suspended adverts */
export function getAllSuspendedAdverts(suspendedData) {
    return axios.post(`${apiUrl}/admin_get_suspended_adverts`, suspendedData);
}

/* Suspended adverts */
export function getAdvertByID(advertData) {
    return axios.post(`${apiUrl}/admin_get_advert_by_id`, advertData);
}

/* Delete advert */

export function deleteAdvert(advertData) {
    return axios.post(`${apiUrl}/admin_deletion_advert`, advertData);
}

/* update advert */

export function updateAdvert(advertData) {
    return axios.post(`${apiUrl}/`, advertData);
}

export function validateAdvert(advertData) {
    return axios.post(`${apiUrl}/admin_validate_advert`, advertData);
}

export function refuseAdvert(advertData) {
    return axios.post(`${apiUrl}/admin_suspend_advert`, advertData);
}
